import { InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { WizardNavigationStore } from '@core/store/wizard/wizard-navigation.store';

export interface IWizardStore {
  form: FormGroup;
  wizardNavigationStore: WizardNavigationStore<string>;
  isServiceAddressSame: boolean;
  goBack(): void;
  goNext(): void;
  submit(): void;
}

export const WIZARD_STORE = new InjectionToken<IWizardStore>('WIZARD_STORE');
