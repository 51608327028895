<div>
  <h2 class="section__title">{{ productForm.getRawValue().name }}</h2>
  <app-wizard-form-section>
    <app-mjc-product-incident-form
      [hasDefaultServicingRetailer]="hasDefaultServicingRetailer"
      [productForm]="productForm"
      (clearRepairActions)="clearRepairActions()"></app-mjc-product-incident-form>
  </app-wizard-form-section>
  <app-wizard-form-section *ngIf="filesForm.value.length > 0">
    <app-files-carousel
      [files]="filesForm.value"
      [showRemove]="true"
      [showDocumentName]="true"
      (removeFileByIndex)="removeFileIndex(filesForm, $event)"></app-files-carousel>
  </app-wizard-form-section>
  <th-form-field-block>
    <ng-container *ngFor="let dropArea of dropAreas">
      <th-form-field [size]="dropArea.sectionSize">
        <app-wizard-form-section [sectionTitle]="dropArea.header">
          <app-form-files-drop-area
            [formFilesArray]="filesForm"
            [labelDescription]="dropArea.labelDescription"
            [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
            [multiple]="dropArea.maxFilesNumber === null"
            [pictureIcon]="dropArea.svgIcon"
            [maxFilesNumber]="dropArea.maxFilesNumber"
            [docType]="dropArea.docType"
            [oneFileErrorMessage]="dropArea.errorMessage"></app-form-files-drop-area>
        </app-wizard-form-section>
      </th-form-field>
    </ng-container>
  </th-form-field-block>
  <div class="form-step-buttons">
    <button
      mat-flat-button
      (click)="goBack()">
      Back
    </button>
    <button
      mat-flat-button
      (click)="goToServiceAction()"
      [disabled]="productForm.invalid || filesForm.invalid">
      Next
    </button>
  </div>
</div>
