import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators } from '@angular/forms';

@Directive({
  selector: '[appMaxLength][ngModel]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxLengthDirective,
      multi: true,
    },
  ],
})
export class MaxLengthDirective implements Validator {
  @Input() set appMaxLength(appMaxLength: number) {
    this.validate = Validators.maxLength(appMaxLength);
  }

  validate = Validators.max(undefined);

}
