import { GridOrderByModel } from '@shared/modules/grid/models/grid/grid-order-by.model';

import { Pagination } from '../../interfaces/ui/pagination.interface';
import { IIdentityString } from '../../interfaces/util/identity.interface';
import { BaseSearchRequest } from '../../models/search/search-request.model';

export namespace StoreState {
  export interface IStoreState {
    items: IIdentityString[];
    searchRequest: BaseSearchRequest;
    pagination: Pagination;
    loading: boolean;
  }

  export const initialState: IStoreState = {
    items: [],
    searchRequest: {
      orderBy: [new GridOrderByModel('name', true)],
      page: 1,
      pageSize: 10,
      searches: [],
      noCount: false,
      noRows: false,
    },
    pagination: {
      totalCount: 0,
      page: 1,
      pageCount: 1,
    },
    loading: false,
  };
}
