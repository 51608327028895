import { CurrencyPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { filter, map, take } from 'rxjs/operators';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { AltTableDirective } from '@shared/directives/table/alt-table.directive';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { IAppTableCol } from '@shared/modules/table/interfaces/table-col.interface';
import { IAppTableRow } from '@shared/modules/table/interfaces/table-row.interface';
import { ThTableComponent } from '@shared/modules/table/table.component';
import { ThTableService } from '@shared/modules/table/table.service';
import { WizardFormSectionComponent } from '@shared/wizard/wizard-form-section/wizard-form-section.component';

import { TProductType } from '../../../../core/enums/product-type.enum';
import { IPlanForm, IRegisterPlanForm } from '../../store/plan-wizard.interface';
import { PlanWizardStore } from '../../store/plan-wizard.store';

interface IPlanSummaryWizardStepState {
  planTableRows: IAppTableRow[];
  planTableColumns: IAppTableCol[];
  submitInProgress: boolean;
  planSubmitted: boolean;
  hiddenColumns: string[];
}

@Component({
  selector: 'app-plan-summary-wizard-step',
  standalone: true,
  imports: [
    ThTableComponent,
    LetDirective,
    AltTableDirective,
    WizardFormSectionComponent,
    MatButtonModule,
    LoadingButtonDirective,
    NgIf,
    LoaderModule,
    MatIconModule,
    MatTooltipModule,
    CurrencyPipe,
  ],
  templateUrl: './plan-summary-wizard-step.component.html',
  styleUrls: ['./plan-summary-wizard-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanSummaryWizardStepComponent {
  store = inject(PlanWizardStore);
  vm$ = this.state.select();
  planForm = this.store.form;

  constructor(
    private readonly state: RxState<IPlanSummaryWizardStepState>,
  ) {
    const plans = (this.store.form.getRawValue() as IRegisterPlanForm).plans;
    const hasWatchPlans = plans.some((plan) => plan.coveredProduct.type === TProductType.Watch);
    this.state.set({
      planTableColumns: this._buildPlanTableColumns(),
      planTableRows: this._buildPlanTableRows(plans),
      hiddenColumns: hasWatchPlans ? ['submitted'] : ['crmManufacturerId', 'submitted'],
    });
    this.state.connect('submitInProgress', this.store.select$('submitInProgress'));
    this.state.connect('planSubmitted', this.store.select$('planSubmitted'));
    this.state.connect('hiddenColumns', this.store.select$('planSubmitResults').pipe(
      filter(planSubmitResults => !!planSubmitResults),
      take(1),
    ), (oldState) => {
      const hiddenColumns = [...oldState.hiddenColumns];
      const submittedColumnIndex = hiddenColumns.indexOf('submitted');
      if (submittedColumnIndex !== -1) {
        hiddenColumns.splice(submittedColumnIndex, 1);
      }
      return hiddenColumns;
    });
  }

  goBack(): void {
    this.store.goBack();
  }

  submit(): void {
    this.store.submit();
  }

  closeDialog(): void {
    this.store.closeDialog();
  }

  private _buildPlanTableColumns(): IAppTableCol[] {
    return [
      ThTableService.buildCol('productDetails', 'Product'),
      ThTableService.buildCol('planName', 'Plan'),
      ThTableService.buildCol('planPrice', 'Purchase Price of Plan'),
      ThTableService.buildCol('receiptNumber', 'Receipt Number'),
      ThTableService.buildCol('submitted', 'Plan Number'),
    ];
  }

  private _buildPlanTableRows(plans: IPlanForm[]): IAppTableRow[] {
    return plans.map((plan, index) => ({
      productDetails: ThTableService.buildCellCustom({
        type: 'productDetails',
        productItem: plan.coveredProduct,
      }),
      planName: ThTableService.buildCellInline(plan.info.planName),
      planPrice: ThTableService.buildCellCurrency(plan.info.planPrice),
      receiptNumber: ThTableService.buildCellInline(plan.info.receiptNumber),
      submitted: ThTableService.buildCellCustom({
        type: 'submitted',
        value$: this.store.select$('planSubmitResults').pipe(
          filter(planSubmitResults => !!planSubmitResults),
          map(planSubmitResults => planSubmitResults[index]),
        ),
      }),
    }));
  }
}
