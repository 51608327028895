import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { finalize } from 'rxjs/operators';

import { IIdentity } from '@core/interfaces/util/identity.interface';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { ThBaseFormControlElement } from '@shared/form/base-form-control-element.directive';

import { RepairTypeApi } from '../../../../core/api/repair-type.api';

interface IRepairTypeCategorySelectState {
  repairCategories: IIdentity<string>[];
  loading: boolean;
}

@Component({
  selector: 'app-repair-type-category-select',
  standalone: true,
  imports: [
    FormSelectComponent,
    LetDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './repair-type-category-select.component.html',
  styleUrls: ['./repair-type-category-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class RepairTypeCategorySelectComponent extends ThBaseFormControlElement<string> {
  vm$ = this.state.select();

  @Output() repairTypeCategoryChanged = new EventEmitter<IIdentity<string>>();

  constructor(
    private readonly repairTypeApi: RepairTypeApi,
    private readonly state: RxState<IRepairTypeCategorySelectState>,
  ) {
    super();
    this.state.set({
      loading: true,
    });
    this.state.connect('repairCategories', this.repairTypeApi.getRepairCategories().pipe(
      finalize(() => this.state.set({ loading: false })),
    ));
  }
}
