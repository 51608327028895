import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { ClaimApiService } from '../../api/claim-api.service';
import { BaseStore } from '../_base/base.store';
import { StoreState } from './quick-search.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

@Injectable({
  providedIn: 'root',
})
export class QuickSearchStore extends BaseStore<IStoreState> {
  constructor(private readonly claimApiService: ClaimApiService) {
    super(initialState);
  }

  search(searchQuery: string): void {
    this.updateState({ searchQuery, searchResults: [] });
    this.requestSearch();
  }

  clearSearch(): void {
    this.updateState({ searchQuery: '', searchResults: [] });
  }

  requestSearch(): void {
    this.updateState({ loading: true });

    const query = this.get('searchQuery');
    this.claimApiService
      .quickSearch(query)
      .pipe(
        finalize(() => {
          this.updateState({ loading: false });
        }),
      )
      .subscribe((searchResults: any) => {
        if (searchResults) {
          this.updateState({
            searchResults,
            noResultsFound: searchResults.length === 0,
          });
        }
      });
  }
}
