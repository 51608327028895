import { Injectable } from '@angular/core';
import { PlanDetailsDialogService } from '@th-common-retailer/shared/dialogs/plan-details/plan-details-dialog.service';
import moment, { parseZone } from 'moment';
import { Observable, of } from 'rxjs';

import { MyProtectionPlanStatus } from '@core/enums/plan/my-protection-plan-status.enum';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import {
  ConsumerPlanStatusGridCellComponent,
} from '@shared/modules/base-plan-details/plan-status-grid-cell/consumer-plan-status-grid-cell.component';
import { GridDateCellComponent } from '@shared/modules/grid/components/grid-date-cell/grid-date-cell.component';
import { GridPriceCellComponent } from '@shared/modules/grid/components/grid-price-cell/grid-price-cell.component';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

import { ProductTypeToName, TProductType } from '../../../core/enums/product-type.enum';

@Injectable({
  providedIn: 'root',
})
export class MjcPlanDetailsDialogService extends PlanDetailsDialogService {
  planColumns = [
    new GridColumnModel('consumerPlanName', 'Plan Number'),
    new GridColumnModel('parentPlanName', 'Plan Description'),
    new GridColumnModel('purchaseDate', 'Purchase', GridDateCellComponent),
    new GridColumnModel('planPrice', 'Plan Price', GridPriceCellComponent),
    new GridColumnModel('purchasePrice', 'Purchase Price', GridPriceCellComponent),
    new GridColumnModel('protectionPlanStatus', 'Plan Status', ConsumerPlanStatusGridCellComponent),
  ];

  coveredProductColumns = [
    new GridColumnModel('mjcProductTypeName', 'Product Type'),
    new GridColumnModel('name', 'Name'),
    new GridColumnModel('productDescription', 'Description'),
    new GridColumnModel('sku', 'SKU'),
    new GridColumnModel('manufacturerName', 'Manufacturer'),
  ];

  showCancelPlanButton(plan: PlanDetails): Observable<boolean> {
    const allowedReturnDaysAfterPurchase = parseZone(plan.planInfo.purchaseDate).add(plan.planInfo.retailerReturnDays, 'days');
    return of(
      plan.planInfo.protectionPlanStatus === MyProtectionPlanStatus.Registered
      && plan.isPlanReturnable
      && moment().isSameOrBefore(allowedReturnDaysAfterPurchase, 'day'),
    );
  }

  parsePlanDetails(planDetails: PlanDetails): PlanDetails {
    return {
      ...planDetails,
      coveredProducts: planDetails.coveredProducts.map(product => ({
        ...product,
        mjcProductTypeName: ProductTypeToName[product.mjcProductType],
        manufacturerName: product.manufacturerAccount?.name,
      })),
    };
  }

  getCoveredProductColumns(planDetails: PlanDetails): GridColumnModel[] {
    return planDetails.coveredProducts[0].mjcProductType === TProductType.Watch
      ? this.coveredProductColumns
      : this.coveredProductColumns.filter(column => column.fieldName !== 'manufacturerName');
  }
}
