import { Directive, Input,TemplateRef, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formGroup]',
  standalone: true,
})
export class FormGroupDirective {

  @Input() set formGroup(formGroup: FormGroup) {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef, { formGroup });
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}
}
