<div *rxLet="vm$; let vm">
  <div mat-dialog-title>
    <h2>Complete Claim</h2>
    <button
      type="button"
      mat-icon-button
      (click)="onClose()"
      [disabled]="vm.loadInProgress">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <th-form-field-block>
      <th-form-field [size]="6">
        <app-form-input
          name="salesTax"
          [(ngModel)]="salesTax"
          label="Sales Tax"
          [appMaxValue]="salesTaxMax"
          [disabled]="vm.loadInProgress"
          [hint]="!!salesTaxMax ? 'Limited to 10% of the authorized amount' : ''"
          [dataType]="'price'"></app-form-input>
      </th-form-field>
    </th-form-field-block>
    <th-form-field-block>
      <th-form-field>
        <p class="claim-complete__files-header">{{ dropArea.header }}</p>
        <div class="files-preview-block">
          <app-files-carousel
            *ngIf="filesForm.value.length > 0"
            [files]="filesForm.value"
            [showRemove]="!vm.loadInProgress"
            [disableControls]="vm.loadInProgress"
            (removeFileByIndex)="removeFiles($event)"></app-files-carousel>
        </div>
        <app-form-files-drop-area
          [disabled]="vm.loadInProgress"
          [formFilesArray]="filesForm"
          [labelDescription]="dropArea.labelDescription"
          [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
          [multiple]="dropArea.maxFilesNumber === null"
          [pictureIcon]="dropArea.svgIcon"
          [maxFilesNumber]="dropArea.maxFilesNumber"
          [docType]="dropArea.docType"></app-form-files-drop-area>
      </th-form-field>
    </th-form-field-block>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      type="button"
      [disabled]="vm.loadInProgress"
      mat-stroked-button
      mat-dialog-close>
      Cancel
    </button>
    <button
      [disabled]="salesTax > salesTaxMax || filesForm.invalid"
      appLoadingButton
      [isLoading]="vm.loadInProgress"
      mat-flat-button
      (click)="completeClaim()">
      Complete
    </button>
  </mat-dialog-actions>
</div>
