import { Optional, Provider } from '@angular/core';
import { ControlContainer, FormGroupDirective, NgForm, NgModelGroup } from '@angular/forms';

export const formViewProvider: Provider = {
  provide: ControlContainer,
  useFactory: _formViewProviderFactory,
  deps: [
    [new Optional(), NgForm],
    [new Optional(), NgModelGroup],
    [new Optional(), FormGroupDirective],
  ],
};

export function _formViewProviderFactory(
  ngForm: NgForm,
  ngModelGroup: NgModelGroup,
  formGroupDirective: FormGroupDirective,
): NgModelGroup | FormGroupDirective | NgForm {
  return ngModelGroup || formGroupDirective || ngForm || null;
}
