<app-notification-popup></app-notification-popup>
<ng-container>
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="showLoader">
  <div class="portal-loader">
    <div class="portal-loader__logo-wrapper">
      <div>
        <img
          class="portal-loader__logo"
          src="../../assets/montage-logos/MJC-One80-White.svg"
          alt="" />
      </div>
    </div>
    <div class="portal-loader__message">
      <span *ngIf="!loadingError">Loading Portal...</span>
      <span
        class="public__error"
        *ngIf="loadingError">
        Oops! There was an error contacting the Portal, please click Refresh or press F5 to try again.
      </span>
    </div>
  </div>
</ng-container>
