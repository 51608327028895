export namespace StoreState {
  export interface IStoreState {
    isLoading: boolean;
    submitInProgress: boolean;
    isLoadingReceiptFiles: boolean;
    activeProductIndex: number;
    claimSubmitted: boolean;
    hasDefaultServicingRetailer: boolean;
  }

  export const initialState: IStoreState = {
    isLoading: false,
    submitInProgress: false,
    isLoadingReceiptFiles: false,
    activeProductIndex: 0,
    claimSubmitted: false,
    hasDefaultServicingRetailer: false,
  };
}
