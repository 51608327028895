import { ChangeDetectionStrategy, Component, inject, ViewChild } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { TServicerType } from '../../../../core/enums/servicing-type.enum';
import {
  IRepairAction,
} from '../../../../core/interfaces/repair-service-action.interface';
import {
  MjcRepairServiceActionFormComponent,
} from '../../components/mjc-repair-service-action-form/mjc-repair-service-action-form.component';
import { IClaimFormModel, IProductFormModel } from '../../interfaces/claim-form.model';
import { MjcCreateClaimStore } from '../../store/mjc-create-claim.store';

@Component({
  selector: 'app-repair-service-action-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MjcRepairServiceActionFormComponent,
    ReactiveFormsModule,
    MatButtonModule,
    FormsModule,
    MatIconModule,
  ],
  templateUrl: './repair-service-action-dialog.component.html',
  styleUrls: ['./repair-service-action-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RepairServiceActionDialogComponent {
  @ViewChild(NgForm, { static: true }) form: NgForm;

  store = inject(MjcCreateClaimStore);
  dialogData: {
    data: {
      title: string;
      repairAction: IRepairAction;
      product: IProductFormModel;
      actionBtnLabel: string;
    };
  } = inject(MAT_DIALOG_DATA);
  matDialogRef = inject(MatDialogRef);
  repairAction: IRepairAction = this.dialogData.data.repairAction || this._returnEmptyRepairAction();
  product = this.dialogData.data.product;

  saveRepairAction(): void {
    const servicerType = (this.store.form.value as IClaimFormModel).product.incident.servicerType;
    const formValue: IRepairAction = this.form.value;

    if (servicerType === TServicerType.ThirdPartyRepairDepot) {
      formValue.calcReimbursementAmount = null;
      formValue.estimatedAmount = null;
    }

    this.matDialogRef.close({
      repairAction: {
        ...this._returnEmptyRepairAction(),
        ...this.form.value,
      },
    });
  }

  private _returnEmptyRepairAction(): IRepairAction {
    return {
      repairTypeCategory: null,
      repairTypeCategoryName: null,
      crmRepairTypeId: null,
      repairTypeName: null,
      estimatedAmount: null,
      calcReimbursementAmount: 0,
      reason: '',
      quantity: 1,
    };
  }
}
