<div mat-dialog-title>
  <p>{{ dialogData.data.title }}</p>
  <button
    mat-icon-button
    mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <form
    id="repair-action-form"
    ngForm
    method="dialog"
    action>
    <app-mjc-repair-service-action-form
      [repairAction]="repairAction"
      [product]="product"></app-mjc-repair-service-action-form>
  </form>
</div>
<div mat-dialog-actions>
  <button
    form="repair-action-form"
    mat-flat-button
    (click)="saveRepairAction()"
    [disabled]="form.invalid">
    {{ dialogData.data.actionBtnLabel }}
  </button>
</div>
