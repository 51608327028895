import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { combineLatestWith, filter, switchMap } from 'rxjs';
import { distinctUntilChanged, finalize, map } from 'rxjs/operators';

import { LookupApiService } from '@core/api/lookup-api.service';
import { IIdentity } from '@core/interfaces/util/identity.interface';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { ThBaseFormControlElement } from '@shared/form/base-form-control-element.directive';

import { RepairTypeApi } from '../../../../core/api/repair-type.api';
import { IRepairType } from '../../../../core/interfaces/repair-service-action.interface';

interface IRepairTypeSelectState {
  repairTypes: IIdentity<string>[];
  loading: boolean;
  repairTypeCategoryId: string;
  productMaterialTypes: IIdentity[];
}

@Component({
  selector: 'app-repair-type-select',
  standalone: true,
  imports: [
    LetDirective,
    FormSelectComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './repair-type-select.component.html',
  styleUrls: ['./repair-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class RepairTypeSelectComponent extends ThBaseFormControlElement<string> implements OnInit {
  @Input() set repairTypeCategoryId(repairTypeCategoryId: string) {
    this.state.set({ repairTypeCategoryId });
  }

  @Output() repairTypeChanged = new EventEmitter<IRepairType>();

  vm$ = this.state.select();
  isFirstLoad = true;

  constructor(
    private readonly repairTypeApi: RepairTypeApi,
    private readonly lookupApiService: LookupApiService,
    private readonly state: RxState<IRepairTypeSelectState>,
  ) {
    super();
    this.state.set({
      loading: false,
      productMaterialTypes: [],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.lookupApiService.getProductMaterialTypes().subscribe(productMaterialTypes => {
      this.state.set({
        productMaterialTypes,
      });
    });
    this.state.connect('repairTypes', this.state.select('repairTypeCategoryId').pipe(
      filter(repairTypeCategoryId => !!repairTypeCategoryId),
      distinctUntilChanged(),
      combineLatestWith(this.state.select('productMaterialTypes').pipe(
        filter(productMaterialTypes => productMaterialTypes.length > 0),
        map(productMaterialTypes => productMaterialTypes.reduce((acc,curr) => {
          acc[curr.id] = curr.name;
          return acc;
        }, {} as {[key: number]: string})),
      )),
      switchMap(([repairTypeCategoryId, productMaterialTypes]) => {
        this.state.set({
          loading: true,
        });
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
        } else {
          this.formControl.reset();
          this.formControl.markAsPristine();
        }
        return this.repairTypeApi.getRepairTypes(repairTypeCategoryId).pipe(
          map(repairTypes => repairTypes.map(repairType => ({
            ...repairType,
            name: `${repairType.name} / ${productMaterialTypes[repairType.crmProductMaterialType]}`,
          }))),
          finalize(() => {
            this.state.set({
              loading: false,
            });
          }),
        );
      }),
    ));
  }
}
