<div *rxLet="vm$; let vm">
  <app-wizard-form-section sectionTitle="Plan and Product">
    <th-table
      appAltTable
      [data]="vm.planTableRows"
      [columns]="vm.planTableColumns"
      [hiddenColumns]="vm.hiddenColumns"
      [isSortable]="false">
      <ng-template let-item>
        <div class="plan-summary__product-details" *ngIf="item.type === 'productDetails'">
          <p><b>{{item.productItem.productName}}</b></p>
          <p *ngIf="item.productItem.manufacturerName">Manufacturer: {{item.productItem.manufacturerName}}</p>
          <p>SKU: {{item.productItem.sku}}</p>
          <p>Unit Price: {{item.productItem.unitPrice | currency}}</p>
          <p class="plan-summary__product-description"
             [matTooltip]="item.productItem.productDescription">Product Description</p>
        </div>
        <ng-container *ngIf="item.type === 'submitted'">
          <div class="plan-summary__submit-result" *rxLet="item.value$; let value">
            <app-loader *ngIf="value.loading"></app-loader>
            <div class="plan-summary__plan-number"
                 *ngIf="value.planNumber">
              <mat-icon class="plan-summary__submit-success">check_circle</mat-icon>
              <p>{{value.planNumber}}</p>
            </div>
            <div class="plan-summary__submit-error"
                 *ngIf="value.errorMessage"
                 [matTooltip]="value.errorMessage">
              <mat-icon color="warn">error</mat-icon>
              <p>Failed</p>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </th-table>
  </app-wizard-form-section>
  <div class="form-step-buttons">
    <ng-container *ngIf="vm.submitInProgress || vm.planSubmitted; else buttonsTemplate">
      <app-loader *ngIf="vm.submitInProgress"></app-loader>
      <div *ngIf="vm.planSubmitted">
        <div class="plan-summary__submit-message-section">
          <mat-icon color="primary">info</mat-icon>
          <p class="plan-summary__submit-message">Consumers will be emailed their protection plan. If no email address is provided, a physical copy will be printed and mailed to the address on file.</p>
        </div>
        <button
          mat-flat-button
          color="primary"
          (click)="closeDialog()">
          Close
        </button>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #buttonsTemplate>
  <button
    mat-flat-button
    color="primary"
    (click)="goBack()">
    Back
  </button>
  <button
    mat-flat-button
    color="primary"
    [disabled]="planForm.invalid"
    (click)="submit()">
    Submit
  </button>
</ng-template>
