import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { IIdentity } from '@core/interfaces/util/identity.interface';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { FormTextareaComponent } from '@shared/components/form-textarea/form-textarea.component';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import {
  ProblemTypeSelectComponent,
} from '@shared/modules/claim-controls/components/problem-type-select/problem-type-select.component';
import { PromptDialogComponent } from '@shared/modules/side-dialog/components/prompt-dialog/prompt-dialog.component';

import { ServicerTypeSelectComponent } from '../../../../shared/servicing-type-select/servicing-type-select.component';
import { IProductFormModel } from '../../interfaces/claim-form.model';

interface IMjcProductIncidentFormState {
  claimType: string;
  initialClaimType: string;
}

@Component({
  selector: 'app-mjc-product-incident-form',
  standalone: true,
  imports: [
    CommonModule,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
    FormSelectComponent,
    ReactiveFormsModule,
    FormDateComponent,
    FormTextareaComponent,
    ProblemTypeSelectComponent,
    ServicerTypeSelectComponent,
    FormInputComponent,
    FormsModule,
    LetDirective,
  ],
  templateUrl: './mjc-product-incident-form.component.html',
  styleUrls: ['./mjc-product-incident-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class MjcProductIncidentFormComponent implements OnInit {
  @Input() productForm: FormGroup;
  @Input() hasDefaultServicingRetailer = false;

  @Output() clearRepairActions = new EventEmitter<void>();
  claimType$ = this.state.select('claimType');

  claimTypes = [
    {
      id: 'Repair',
      name: 'Product Repair',
    },
    {
      id: 'Replacement',
      name: 'Product Replacement',
    },
  ];

  today = new Date();
  selectedServicerType: number;

  protected readonly TCommonFieldSize = TCommonFieldSize;

  constructor(
    private readonly state: RxState<IMjcProductIncidentFormState>,
    private readonly modalDialogService: ModalDialogService,
  ) {}

  ngOnInit(): void {
    const claimType = (this.productForm.value as IProductFormModel).incident.claimType || '';
    this.state.set({
      claimType,
      initialClaimType: claimType,
    });
    this.selectedServicerType = this.productForm.get('incident.servicerType').value;
  }

  claimTypeChanged(claimType: { name: string; id: 'Repair' | 'Replacement' }): void {
    const initialClaimType = this.state.get('initialClaimType');

    if (!initialClaimType) {
      this._setClaimType(claimType);
      return;
    }

    const prevClaimType = this.state.get('claimType');

    if (prevClaimType === 'Repair') {
      this.modalDialogService.open({
        title: 'Claim Type Changed',
        data: {
          message: 'All added repair actions will be removed. Do you want to continue?',
          buttonNo: 'Cancel',
          buttonYes: 'Continue',
        },
      }, PromptDialogComponent, {
        disableClose: true,
      }).afterClosed().subscribe(result => {
        if (result) {
          this.clearRepairActions.emit();
          this._setClaimType(claimType);
        } else {
          this.productForm.get('incident.claimType').patchValue(prevClaimType);
        }
      });
    } else {
      this._setClaimType(claimType);
    }
  }

  setServicerType(servicerType: IIdentity): void {
    if (!this.selectedServicerType) {
      this.productForm.get('incident.servicerTypeName').setValue(servicerType.name);
      return;
    }

    this.modalDialogService.open({
      title: 'Servicer Type Changed',
      data: {
        message: 'All added repair actions will be removed. Do you want to continue?',
        buttonNo: 'Cancel',
        buttonYes: 'Continue',
      },
    }, PromptDialogComponent, {
      disableClose: true,
    }).afterClosed().subscribe(result => {
      if (result) {
        this.clearRepairActions.emit();
        this.productForm.get('incident.servicerTypeName').setValue(servicerType.name);
        this.selectedServicerType = servicerType.id;
      } else {
        this.productForm.get('incident.servicerType').patchValue(this.selectedServicerType);
      }
    });
  }

  private _setClaimType(claimType: { name: string; id: 'Repair' | 'Replacement' }): void {
    this.state.set({
      claimType: claimType.id,
    });
    if (claimType.id === 'Replacement') {
      this.productForm.get('incident.servicerType').removeValidators(Validators.required);
      this.productForm.get('incident.servicerTypeName').setValue('');
      this.productForm.get('incident.servicerType').setValue(null);
    } else {
      this.productForm.get('incident.servicerType').setValidators(Validators.required);
    }
  }
}
