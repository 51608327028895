<div *rxLet="vm$; let vm">
  <ng-container [ngSwitch]="claimType">
    <app-wizard-form-section
      *ngSwitchCase="'Replacement'"
      sectionTitle="Product Replacement"
    >
      <ng-container [formGroup]="replacementActionForm">
        <app-claim-replacement-template-form></app-claim-replacement-template-form>
      </ng-container>
    </app-wizard-form-section>
    <app-wizard-form-section
      *ngSwitchCase="'Repair'"
      sectionTitle="Product Repair Actions"
    >
      <th-table
        class="mjc-claim-service-actions-wizard__table"
        appAltTable
        *ngIf="vm.repairActions.length > 0"
        [data]="vm.repairActionsTable.rows"
        [columns]="vm.repairActionsTable.columns"
        [isSortable]="false"
      >
        <ng-template let-item>
          <ng-container *ngIf="item.type === 'actions'">
            <app-mjc-repair-action-form-actions-cell
              [repairAction]="item.repairAction"
              [repairActionIndex]="item.repairActionIndex"></app-mjc-repair-action-form-actions-cell>
          </ng-container>
        </ng-template>
      </th-table>
      <div class="mjc-claim-service-actions-wizard__total-amount" *ngIf="vm.totalEstimatedReimbursementAmount !== null">
        <p class="mjc-claim-service-actions-wizard__total-amount-label">Total Amount:</p>
        <p>{{vm.totalEstimatedReimbursementAmount | currency}}</p>
      </div>
      <button
        class="mjc-claim-service-actions-wizard__add-repair-action-btn"
        mat-flat-button
        (click)="addNewRepairAction(repairActionDialogTemplate)"
      >
        Add Repair Action
      </button>
    </app-wizard-form-section>
  </ng-container>
  <div class="form-step-buttons">
    <button mat-flat-button (click)="goBack()">Back</button>
    <button mat-flat-button
            [disabled]="form.invalid"
            (click)="submit()"
            appLoadingButton
            [isLoading]="vm.submitInProgress">Submit</button>
  </div>
</div>
<ng-template #repairActionDialogTemplate>
  <app-repair-service-action-dialog></app-repair-service-action-dialog>
</ng-template>
