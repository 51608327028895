<div class="wizard-nav">
  <ng-container *ngFor="let nav of navigationList">
    <div
      class="wizard-nav__item"
      [class.wizard-nav__item_active]="nav.id === activeStep || nav.active">
      <p class="plan-wizard__title">{{ nav.title }}</p>
      <div
        *rxIf="nav.valid$"
        class="wizard-nav__icon">
        <mat-icon class="_common-icon--white">done</mat-icon>
      </div>
    </div>
    <div
      class="wizard-nav__children"
      *ngIf="nav.children && nav.children.length > 0">
      <div
        class="wizard-nav__item"
        [class.wizard-nav__item_active]="childNav.active"
        *ngFor="let childNav of nav.children">
        <p class="wizard-nav__title">{{ childNav.title }}</p>
        <div
          *rxIf="childNav.valid$"
          class="wizard-nav__icon">
          <mat-icon class="_common-icon--white">done</mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
</div>
