import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';

import { PRODUCT_FILES_DROP_AREA, RECEIPT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { IDropArea } from '@core/interfaces/claims/files.interface';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { FilesCarouselComponent } from '@shared/modules/files/components/files-carousel/files-carousel.component';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';
import { WizardFormSectionComponent } from '@shared/wizard/wizard-form-section/wizard-form-section.component';

import {
  MjcProductIncidentFormComponent,
} from '../../components/mjc-product-incident-form/mjc-product-incident-form.component';
import { MjcCreateClaimStore } from '../../store/mjc-create-claim.store';

@Component({
  selector: 'app-mjc-product-incident-wizard',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MjcProductIncidentFormComponent,
    FormFilesDropAreaComponent,
    LetDirective,
    PushPipe,
    WizardFormSectionComponent,
    FilesCarouselComponent,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
  ],
  templateUrl: './mjc-product-incident-wizard.component.html',
  styleUrls: ['./mjc-product-incident-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MjcProductIncidentWizardComponent {
  store = inject(MjcCreateClaimStore);
  fb = inject(FormBuilder);
  productForm = this.store.form.get('product') as FormGroup;
  filesForm = this.store.form.get('files') as FormArray;
  initialClaimType = this.productForm.get('incident.claimType').value;
  dropAreas: IDropArea[] = [{
    ...RECEIPT_FILES_DROP_AREA,
    header: 'Receipt',
  }, {
    ...PRODUCT_FILES_DROP_AREA,
    docType: TDocumentType._DamagePhoto,
    header: 'Damage Photo(s)',
  }];
  hasDefaultServicingRetailer = this.store.get('hasDefaultServicingRetailer');

  protected readonly TDocumentType = TDocumentType;

  goBack(): void {
    this.store.goBack();
  }

  goToServiceAction(): void {
    const claimType = this.productForm.get('incident.claimType').value;
    if (claimType !== this.initialClaimType) {
      this.store.clearServiceActions();
    }

    if (claimType === 'Repair') {
      this.store.form.get('replacementAction').disable();
      this.store.form.get('repairActions').enable();
    } else {
      this.store.form.get('repairActions').disable();
      this.store.form.get('replacementAction').enable();
    }
    this.store.goNext();
  }

  removeFileIndex(damageFilesForm: FormArray, fileIndex: number): void {
    const updatedFiles = damageFilesForm.value.filter(file => fileIndex !== file.index);
    damageFilesForm.clear();
    updatedFiles.forEach(file => {
      damageFilesForm.push(this.fb.control(file));
    });
  }

  clearRepairActions(): void {
    this.store.clearRepairActions();
  }
}
