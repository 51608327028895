<div
  class="plan-consumer-wizard-step"
  *rxLet="vm$; let vm">
  <ng-container [ngSwitch]="vm.currentSubStep">
    <ng-container *ngSwitchCase="TConsumerSubStep.Search">
      <div class="plan-consumer-wizard-step__quick-search">
        <ng-container *ngIf="!vm.searchLoading; else searchLoadingTemplate">
          <app-consumer-search-control>
            <app-consumer-default-search-for-options searchForOptions></app-consumer-default-search-for-options>
            <ng-container orSearchView *ngIf="vm.showNewConsumerBtn">
              <app-divider>OR</app-divider>
              <button (click)="registerNewConsumer()" mat-stroked-button>Register Plan for a New Consumer</button>
            </ng-container>
          </app-consumer-search-control>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="TConsumerSubStep.Select">
      <div class="plan-consumer-wizard-step__plan-for-new-consumer">
        <p>If this is not the consumer you were looking for you can register a new plan here</p>
        <button (click)="registerNewConsumer()" mat-stroked-button>Register Plan for a New Consumer</button>
      </div>
      <div class="plan-consumer-wizard-step__results">
        <ng-container *ngFor="let consumer of vm.searchResults">
          <app-quick-search-consumer
            [consumer]="consumer"
            (openConsumerDetails)="onConsumerClicked(consumer)"></app-quick-search-consumer>
        </ng-container>
      </div>
      <div class="form-step-buttons">
        <button
          type="button"
          mat-flat-button
          color="primary"
          (click)="backToSearch()">
          Back
        </button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="TConsumerSubStep.Form">
      <h2 class="section__title">Consumer</h2>
      <app-consumer-template-form
        [formGroupRef]="consumerForm.get('info')"
        [phonesRequired]="phonesRequired"
        [useRadio]="false"></app-consumer-template-form>
      <h3 class="section__title">Consumer Address</h3>
      <app-address-template-form
        [formGroup]="consumerForm.get('consumerAddress')"></app-address-template-form>
      <app-address-suggest
        *ngIf="consumerForm.get('consumerAddress').enabled"
        [addressForm]="consumerForm.get('consumerAddress')"
        (isInProgress)="setInProgressState($event)"></app-address-suggest>
      <div class="form-step-buttons">
        <button
          mat-flat-button
          color="primary"
          (click)="backToSearch()">
          Back
        </button>
        <button
          mat-flat-button
          color="primary"
          [disabled]="consumerForm.invalid"
          (click)="nextStep()">
          Next
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
<ng-template #searchLoadingTemplate>
  <app-loader></app-loader>
</ng-template>
