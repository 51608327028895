import { Injectable } from '@angular/core';

import { BaseStore } from '@core/store/_base/base.store';

import { StoreState } from './mjc-dashboard.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

import { CompositeTileService } from '@th-common-retailer/services/composite-tile.service';
import { DashboardTileService } from '@th-common-retailer/services/dashboard-tile.service';

import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';

import { COMPOSITE_TILE_SEARCH_REQUESTS, MjcTileFilter } from '../../../core/constants/mjc-tile-stage-filter.constant';

@Injectable({
  providedIn: 'root',
})
export class MjcDashboardStore extends BaseStore<IStoreState> {
  constructor(
    private readonly dashboardTileService: DashboardTileService,
    private readonly compositeTileService: CompositeTileService,
  ) {
    super(initialState);
  }

  getTiles(): void {
    const tiles = this.get('tiles');
    const actionTiles = this.get('actionTiles');
    const statTiles = this.get('statTiles');

    tiles.forEach(tile => {
      switch (tile.serviceActionType) {
        case TServiceActionType.Repairs:
          this._getRepairsTiles();
          break;
        case TServiceActionType.Replacement:
          this._getReplacementTiles();
          break;
      }
    });
    this.dashboardTileService.getTilesData(actionTiles, null).subscribe(actionTilesResult => {
      this.updateState({ actionTiles: actionTilesResult });
    });
    this.dashboardTileService.getTilesData(statTiles, null).subscribe(statTilesResult => {
      this.updateState({ statTiles: statTilesResult });
    });
  }

  private _getRepairsTiles(): void {
    const repairsStages = [
      TServiceActionTileStage.RepairAuthorized,
      TServiceActionTileStage.ReadyToShip,
    ];
    const serviceActionType = TServiceActionType.Repairs;
    this.compositeTileService.requestCompositeTile(
      COMPOSITE_TILE_SEARCH_REQUESTS,
      MjcTileFilter,
      serviceActionType,
      repairsStages,
    ).subscribe(stages => {
      const tiles = this.get('tiles');
      this.updateState({
        tiles: tiles.map(tile =>
          tile.serviceActionType === serviceActionType
            ? this.compositeTileService.parseCompositeTile(tile, stages, repairsStages) : tile,
        ),
      });
    });
  }

  private _getReplacementTiles(): void {
    const replacementStages = [
      TServiceActionTileStage.ReplacementAuthorized,
    ];
    const serviceActionType = TServiceActionType.Replacement;
    this.compositeTileService.requestCompositeTile(
      COMPOSITE_TILE_SEARCH_REQUESTS,
      MjcTileFilter,
      serviceActionType,
      replacementStages,
    ).subscribe(stages => {
      const tiles = this.get('tiles');
      this.updateState({
        tiles: tiles.map(tile =>
          tile.serviceActionType === serviceActionType
            ? this.compositeTileService.parseCompositeTile(tile, stages, replacementStages) : tile,
        ),
      });
    });
  }
}
