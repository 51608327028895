<div *rxLet="vm$; let vm" class="consumer-search-control__wrapper">
  <div *ngIf="vm.noResultsFound" class="consumer-search-control__no-results">
    <p>No records were found that match your search.</p>
    <p>Please try a different search.</p>
  </div>
  <div class="consumer-search-control__input">
    <app-search-input
      [canBounce]="false"
      (search)="quickSearch($event)"
      (clearSearch)="clearSearch()"
      [disabled]="vm.searchLoading"></app-search-input>
  </div>
  <p>You can search for:</p>
  <ng-content select="[searchForOptions]"></ng-content>
  <ng-content select="[orSearchView]"></ng-content>
</div>
