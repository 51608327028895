<div [formGroup]="productForm.get('incident')">
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-problem-type-select
        formControlName="problemType"
        [required]="true"></app-problem-type-select>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-date
        formControlName="dateNoticed"
        label="Date Noticed"
        [max]="today"></app-form-date>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Large">
      <app-form-textarea
        formControlName="problemDescription"
        label="Problem Description"></app-form-textarea>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-select
        formControlName="claimType"
        label="Claim Type"
        [data]="claimTypes"
        (itemSelected)="claimTypeChanged($event)"></app-form-select>
    </th-form-field>
    <ng-container *rxLet="claimType$; let selectedClaimType">
      <th-form-field
        *ngIf="selectedClaimType === 'Repair'"
        [size]="TCommonFieldSize.Medium">
        <app-servicer-type-select
          [hasDefaultServicingRetailer]="hasDefaultServicingRetailer"
          formControlName="servicerType"
          (servicerTypeChanged)="setServicerType($event)"></app-servicer-type-select>
      </th-form-field>
    </ng-container>
  </th-form-field-block>
</div>
