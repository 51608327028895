import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';

import { IWizardNavigation } from '@core/interfaces/wizard-navigation.interface';

@Component({
  selector: 'app-wizard-nav',
  standalone: true,
  imports: [LetDirective, RxIf, MatIconModule, NgForOf, NgIf, PushPipe],
  templateUrl: './wizard-nav.component.html',
  styleUrls: ['./wizard-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardNavComponent<T> {
  @Input() navigationList: IWizardNavigation<T>[];
  @Input() activeStep: T;

}
