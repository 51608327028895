import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { Claim } from '@core/interfaces/claims/claim.interface';

import { MjcClaimsPageService } from '../../mjc-claims-page.service';

@Component({
  selector: 'app-mjc-claim-details-cell',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mjc-claim-details-cell.component.html',
  styleUrls: ['./mjc-claim-details-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MjcClaimDetailsCellComponent {
  data: Claim;
  fieldName: string;

  constructor(
    private readonly router: Router,
    private readonly mjcClaimsPageService: MjcClaimsPageService,
  ) {}

  openClaimDetails(claim: Claim): void {
    if (this.router.url.includes('claims')) {
      this.router.navigate([], {
        queryParams: { id: claim.id },
      });
    } else {
      this.mjcClaimsPageService.openClaimDetailsDialog(claim.id, true);
    }
  }
}
