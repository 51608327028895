import { Injectable, TemplateRef } from '@angular/core';

import { BaseStore } from '@core/store/_base/base.store';

import { StoreState } from './mjc-create-claim.state';

import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, tap, zip } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { ClaimApiService } from '@core/api/claim-api.service';
import { RetailerPlanApiService } from '@core/api/retailer-plan-api.service';
import { TDocumentType } from '@core/enums/document-type';
import { BaseConsumer, Retailer } from '@core/interfaces/claims/claimDetails.interface';
import { Files } from '@core/interfaces/claims/files.interface';
import { PlanDetails } from '@core/interfaces/plan/plan.interface';
import { IWizardNavigation } from '@core/interfaces/wizard-navigation.interface';
import { ClaimFormService } from '@core/services/claim-form.service';
import { IWizardStore } from '@core/store/wizard/wizard.store';
import { WizardNavigationStore } from '@core/store/wizard/wizard-navigation.store';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { AddressType } from '@shared/modules/claim-dialogs/enums/address-type.enum';
import { ClaimSuccessComponent } from '@shared/modules/side-dialog/components/claim-success/claim-success.component';

import { IRepairAction } from '../../../core/interfaces/repair-service-action.interface';
import {
  RepairServiceActionDialogComponent,
} from '../dialogs/repair-service-action-dialog/repair-service-action-dialog.component';
import { IClaimFormModel, IConsumerFormModel, IProductFormModel } from '../interfaces/claim-form.model';

function validateReceiptFiles() {
  return (formArray: FormArray<FormControl<Files>>) => {
    const receiptFiles = formArray.value.filter(file => file.docType === TDocumentType.Receipt && !file.isReadOnly);
    if (receiptFiles.length > 0) {
      return null;
    } else {
      return {
        filesRequired: true,
        errorMessage: 'Please upload receipt.',
      };
    }
  };
}

function validateDamageFiles() {
  return (formArray: FormArray<FormControl<Files>>) => {
    const damageFiles = formArray.value.filter(file => file.docType === TDocumentType._DamagePhoto);
    if (damageFiles.length > 0) {
      return null;
    } else {
      return {
        filesRequired: true,
        errorMessage: 'Please upload at least one damage photo.',
      };
    }
  };
}

export type TMjcClaimWizardSteps = 'consumer' | 'product' | 'serviceActions';

@Injectable()
export class MjcCreateClaimStore extends BaseStore<IStoreState> implements IWizardStore {
  form: FormGroup;
  phonesRequired = true;

  isServiceAddressSame = false;

  constructor(
    public readonly wizardNavigationStore: WizardNavigationStore<TMjcClaimWizardSteps>,
    private readonly fb: FormBuilder,
    private readonly claimFormService: ClaimFormService,
    private readonly retailerPlanApiService: RetailerPlanApiService,
    private readonly modalDialogService: ModalDialogService,
    private readonly claimApiService: ClaimApiService,
  ) {
    super(initialState);
  }

  get replacementActionForm(): FormGroup {
    return this.form.get('replacementAction') as FormGroup;
  }

  get repairActionsForm(): FormArray {
    return this.form.get('repairActions') as FormArray;
  }

  initForm(planId: number): Observable<PlanDetails> {
    this.updateState({
      isLoading: true,
    });
    const detailedPlanRequest$ = this.retailerPlanApiService.detailed(planId);
    const filesRequest$ = this.retailerPlanApiService.getFiles(planId);
    return zip(detailedPlanRequest$, filesRequest$).pipe(
      tap(([planDetails, files]) => {
        const consumerModel = this._consumerToForm(planDetails.consumer);
        this._setForm(consumerModel, planDetails);
        const receiptFiles = files.filter(file => file.docType === TDocumentType.Receipt).map((file, index) => this.fb.control({
          ...file,
          isReadOnly: true,
          index,
        }));
        receiptFiles.forEach(receiptFile => {
          (this.form.get('files') as FormArray).push(receiptFile);
        });
        if (files.filter(file => file.docType === TDocumentType.Receipt).length === 0) {
          this.form.get('files').addValidators([validateReceiptFiles()]);
        }
      }),
      map(([planDetails]) => planDetails),
      finalize(() => {
        this.updateState({
          isLoading: false,
        });
      }),
    );
  }

  goBack(): void {
    this.wizardNavigationStore.stepBack();
  }

  goNext(): void {
    this.wizardNavigationStore.nextStep();
  }

  clearServiceActions(): void {
    this.replacementActionForm.reset();
    this.clearRepairActions();
  }

  clearRepairActions(): void {
    this.repairActionsForm.clear();
  }

  addNewRepairAction(repairAction: IRepairAction): void {
    const repairServiceActionForm = this.getRepairServiceActionFields();
    repairServiceActionForm.setValue(repairAction);
    this.repairActionsForm.push(repairServiceActionForm);
  }

  updateRepairAction(repairAction: IRepairAction, index: number): void {
    (this.form.get('repairActions') as FormArray).at(index).setValue(repairAction);
  }

  deleteRepairAction(index: number): void {
    (this.form.get('repairActions') as FormArray).removeAt(index);
  }

  getRepairServiceActionFields(): FormGroup {
    return this.fb.group({
      repairTypeCategory: [{
        value: null,
        disabled: true,
      }, Validators.required],
      repairTypeCategoryName: [{
        value: null,
        disabled: true,
      }, Validators.required],
      crmRepairTypeId: [null, Validators.required],
      repairTypeName: [{
        value: null,
        disabled: true,
      }, Validators.required],
      estimatedAmount: [null],
      quantity: [1, Validators.required],
      reimbursementAmount: [{
        value: null,
        disabled: true,
      }],
      calcReimbursementAmount: [null],
      crmCalculationType: [{
        value: null,
        disabled: true,
      }],
      pricedByUnit: [{
        value: null,
        disabled: true,
      }],
      repairTypeDescription: [{
        value: null,
        disabled: true,
      }],
      reason: ['', [Validators.required, Validators.maxLength(2000)]],
    });
  }

  openRepairActionDialog(
    data: {repairAction?: IRepairAction; product: IProductFormModel},
    repairActionDialogTemplate: TemplateRef<RepairServiceActionDialogComponent>,
    config: {title: string; actionBtnLabel: string},
  ): MatDialogRef<RepairServiceActionDialogComponent, {repairAction: IRepairAction}> {
    return this.modalDialogService.openFromTemplate({
      title: 'Repair Action Dialog',
      data: {
        ...data,
        ...config,
      },
    }, repairActionDialogTemplate, {
      width: '600px',
      maxWidth: '600px',
      disableClose: true,
    });
  }

  submit(): void {
    this.updateState({
      submitInProgress: true,
    });
    const {
      consumer, plan, product, repairActions, replacementAction, files,
    }: IClaimFormModel = this.form.value;

    this.claimApiService.createMjcClaim({
      crmConsumerPlanId: plan.crmConsumerPlanId,
      productDescription: '',
      crmManufacturerId: null,
      problemType: product.incident.problemType,
      dateNoticed: product.incident.dateNoticed,
      problemDescription: product.incident.problemDescription,
      claimType: product.incident.claimType,
      servicerType: product.incident.servicerType,
      repairActions: (repairActions || []).map(repairAction => ({
        crmRepairTypeId: repairAction.crmRepairTypeId,
        unitCost: repairAction.estimatedAmount || repairAction.calcReimbursementAmount,
        reason: repairAction.reason,
        quantity: repairAction.quantity,
      })),
      replacement: replacementAction ? {
        cost: replacementAction.replacementPrice,
        reason: replacementAction.replacementReason,
      } : null,
      consumer: consumer.info,
    }, files).pipe(
      finalize(() => {
        this.updateState({
          submitInProgress: true,
        });
      }),
    ).subscribe(response => {
      this.form.markAsPristine();
      this.updateState({
        claimSubmitted: true,
      });
      this.modalDialogService.open(
        {
          title: 'Claim success',
          data: {
            claim: {
              claimNumber: response.claimNumber,
            },
          },
        },
        ClaimSuccessComponent,
        {
          disableClose: true,
          width: 'auto',
        },
      );
    });
  }

  setNavigationList(navigationList: IWizardNavigation<TMjcClaimWizardSteps>[]): void {
    this.wizardNavigationStore.setNavigationList(navigationList);
  }

  selectNavigationList$(): Observable<IWizardNavigation<TMjcClaimWizardSteps>[]> {
    return this.wizardNavigationStore.selectNavigationList$();
  }

  setDefaultServicingRetailer(retailer: Retailer): void {
    this.updateState({
      hasDefaultServicingRetailer: !!retailer.defaultServicingRetailer,
    });
  }

  private _setForm(consumerModel: IConsumerFormModel, plan: PlanDetails): void {
    const product = plan.coveredProducts[0];
    this.form = this.fb.group({
      consumer: this.fb.group({
        info: this.claimFormService.getConsumerFields(consumerModel ? consumerModel.info : {}),
        consumerAddress: this.claimFormService.getAddressFields(
          consumerModel ? consumerModel.consumerAddress : {}, AddressType.CustomerAddress,
        ),
      }),
      plan: this.fb.group({
        crmConsumerPlanId: [plan.planInfo.crmConsumerPlanId || null],
        name: [{
          value: plan.planInfo.consumerPlanName || null,
          disabled: true,
        }],
      }),
      product: this.fb.group({
        id: [product.id || null, Validators.required],
        name: [{
          value: product.name || null,
          disabled: true,
        }, Validators.required],
        incident: this.fb.group({
          problemType: [null, Validators.required],
          problemDescription: ['', [Validators.required, Validators.maxLength(2000)]],
          dateNoticed: [null, Validators.required],
          claimType: [null, Validators.required],
          servicerType: [null],
          servicerTypeName: [{
            value: null,
            disabled: true,
          }],
        }),
      }),
      replacementAction: this._getReplacementActionFields(),
      repairActions: this.fb.array([], Validators.required),
      files: this.fb.array<Files>([], [validateDamageFiles()]),
    });
    const emailAddressForm = this.form.get('consumer.info.emailAddress');
    const mobilePhoneForm = this.form.get('consumer.info.mobilePhone');
    const homePhoneForm = this.form.get('consumer.info.homePhone');
    const workPhoneForm = this.form.get('consumer.info.workPhone');
    this.form.get('consumer').disable();
    this.form.get('consumer.info.id').enable();
    mobilePhoneForm.enable();
    homePhoneForm.enable();
    workPhoneForm.enable();
    emailAddressForm.enable();
    if (!consumerModel.info.emailAddress) {
      emailAddressForm.removeValidators(Validators.required);
    }
    if (!consumerModel.info.mobilePhone && !consumerModel.info.homePhone && !consumerModel.info.workPhone) {
      this.phonesRequired = false;
      this.form.get('consumer.info').removeValidators(ClaimFormService.ValidateConsumerPhones);
    } else {
      this.phonesRequired = true;
      this.form.get('consumer.info').addValidators(ClaimFormService.ValidateConsumerPhones);
    }
  }

  private _consumerToForm(consumer: BaseConsumer): IConsumerFormModel {
    return {
      info: {
        id: consumer.id,
        crmRefId: consumer.crmRefId,
        firstName: consumer.firstName,
        lastName: consumer.lastName,
        emailAddress: consumer.emailAddress,
        mobilePhone: consumer.mobilePhone,
        homePhone: consumer.homePhone,
        workPhone: consumer.workPhone,
        preferredContactMethod: consumer.preferredContactMethod,
        preferredContactTime: consumer.preferredContactTime,
        preferredContactLanguage: consumer.preferredContactLanguage,
      },
      consumerAddress: {
        streetAddress1: consumer.streetAddress1,
        streetAddress2: consumer.streetAddress2,
        city: consumer.city,
        postalCode: consumer.postalCode,
        stateProvince: consumer.stateProvince,
      },
    };
  }

  private _getReplacementActionFields(): FormGroup {
    return this.fb.group({
      replacementReason: ['', [Validators.required, Validators.maxLength(2000)]],
      replacementPrice: [null, Validators.required],
    });
  }
}
