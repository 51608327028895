import { NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import {
  QuickSearchConsumerComponent,
} from '@th-common-retailer/shared/components/quick-search-consumer/quick-search-consumer.component';
import { skip, withLatestFrom } from 'rxjs/operators';

import { QuickSearchConsumer } from '@core/interfaces/quick-search/search-results.interface';
import { ClaimFormService } from '@core/services/claim-form.service';
import { QuickSearchStore } from '@core/store/quick-search/quick-search.store';
import { DividerComponent } from '@shared/components/divider/divider.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { SwitchComponent } from '@shared/components/switch/switch.component';
import {
  AddressSuggestComponent,
} from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.component';
import { ADDRESS_SUGGEST_SCROLL_SELECTOR } from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.token';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import {
  AddressTemplateFormComponent,
} from '@shared/standalone/claim-forms/address-template-form/address-template-form.component';
import {
  ConsumerTemplateFormComponent,
} from '@shared/standalone/claim-forms/consumer-template-form/consumer-template-form.component';

import {
  ConsumerDefaultSearchForOptionsComponent,
} from '../../../../shared/consumer-search-control/components/consumer-default-search-for-options/consumer-default-search-for-options.component';
import {
  ConsumerSearchControlComponent,
} from '../../../../shared/consumer-search-control/consumer-search-control.component';
import { PlanWizardStore } from '../../store/plan-wizard.store';

enum TConsumerSubStep {
  Search = 1,
  Select,
  Form
}

interface IFppClaimConsumerState {
  searchResults: QuickSearchConsumer[];
  currentSubStep: TConsumerSubStep;
  searchLoading: boolean;
  // noResultsFound: boolean;
  addressValidationInProgress: boolean;
  showNewConsumerBtn: boolean;
}

@Component({
  selector: 'app-plan-consumer-wizard-step',
  templateUrl: './plan-consumer-wizard-step.component.html',
  styleUrls: ['./plan-consumer-wizard-step.component.scss'],
  standalone: true,
  imports: [
    LetDirective,
    NgSwitch,
    NgSwitchCase,
    SearchInputComponent,
    NgForOf,
    MatButtonModule,
    NgIf,
    LoaderModule,
    AddressSuggestComponent,
    AddressTemplateFormComponent,
    ConsumerTemplateFormComponent,
    SwitchComponent,
    FormsModule,
    DividerComponent,
    ConsumerDefaultSearchForOptionsComponent,
    ConsumerSearchControlComponent,
    QuickSearchConsumerComponent,
  ],
  providers: [
    RxState,
    QuickSearchStore,
    {
      provide: ADDRESS_SUGGEST_SCROLL_SELECTOR,
      useValue: '.plan-wizard__content',
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanConsumerWizardStepComponent {
  vm$ = this.state.select();
  TConsumerSubStep = TConsumerSubStep;
  consumerForm = this.store.form.get('consumer');
  phonesRequired = true;

  constructor(
    public readonly store: PlanWizardStore,
    private readonly state: RxState<IFppClaimConsumerState>,
    private readonly quickSearchStore: QuickSearchStore,
  ) {
    const consumeInfoForm = this.store.form.get('consumer.info').value;
    this.state.set({
      currentSubStep: consumeInfoForm.id || consumeInfoForm.emailAddress ? TConsumerSubStep.Form : TConsumerSubStep.Search,
      addressValidationInProgress: false,
      showNewConsumerBtn: false,
    });
    if (this.state.get('currentSubStep') !== TConsumerSubStep.Form) {
      this.store.hideAlertMessage();
    }
    this.state.connect('searchLoading', this.quickSearchStore.select$('loading'));
    this.state.connect(this.quickSearchStore.select$('searchResults').pipe(
      skip(1),
      withLatestFrom(this.quickSearchStore.select$('searchQuery')),
    ), (oldState, [searchResults, searchQuery]) => ({
      ...oldState,
      searchResults,
      currentSubStep: searchResults.length > 0 ? TConsumerSubStep.Select : TConsumerSubStep.Search,
      showNewConsumerBtn: searchQuery && searchResults.length === 0,
    }));
  }

  goBack(): void {
    this.store.goBack();
  }

  onConsumerClicked(consumer: QuickSearchConsumer): void {
    this.consumerForm.get('info').patchValue(consumer);
    this.consumerForm
      .get('consumerAddress')
      .patchValue({
        streetAddress1: consumer.streetAddress1,
        streetAddress2: consumer.streetAddress2,
        city: consumer.city,
        stateProvince: consumer.stateProvince,
        postalCode: consumer.postalCode,
        isValidated: false,
        addressValidationAttempted: false,
      });
    this.consumerForm.get('consumerAddress').disable();
    this.consumerForm.get('info.firstName').disable();
    this.consumerForm.get('info.lastName').disable();
    if (consumer.emailAddress) {
      this.consumerForm.get('info.emailAddress').addValidators(Validators.required);
    } else {
      this.consumerForm.get('info.emailAddress').removeValidators(Validators.required);
    }

    if (!consumer.mobilePhone && !consumer.homePhone && !consumer.workPhone) {
      this.phonesRequired = false;
      this.consumerForm.get('info').removeValidators(ClaimFormService.ValidateConsumerPhones);
    } else {
      this.phonesRequired = true;
      this.consumerForm.get('info').addValidators(ClaimFormService.ValidateConsumerPhones);
    }
    this.state.set({
      currentSubStep: TConsumerSubStep.Form,
    });
    this.store.showAlertMessage();
    this.consumerForm.markAllAsTouched();
  }

  backToSearch(): void {
    this.state.set({
      currentSubStep: TConsumerSubStep.Search,
      showNewConsumerBtn: false,
    });
    this.store.hideAlertMessage();
    this.quickSearchStore.clearSearch();
    this.store.clearConsumer();
  }

  nextStep(): void {
    this.store.ws.setStep('product0');
  }

  setInProgressState(addressValidationInProgress: boolean): void {
    this.state.set({
      addressValidationInProgress,
    });
  }

  registerNewConsumer(): void {
    this.consumerForm.get('info.emailAddress').removeValidators(Validators.required);
    this.state.set({
      currentSubStep: TConsumerSubStep.Form,
    });
    this.store.showAlertMessage();
  }
}
