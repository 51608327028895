import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { ClaimApiService } from '@core/api/claim-api.service';
import { RECEIPT_FILES_DROP_AREA } from '@core/constants/app.constants';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { Files } from '@core/interfaces/claims/files.interface';
import { validateArrayMaxFilesSize } from '@shared/common/form-validators';
import { FormFieldComponent } from '@shared/components/form-field/form-field.component';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { MaxValueDirective } from '@shared/directives/validators/max-value/max-value.directive';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { FilesCarouselComponent } from '@shared/modules/files/components/files-carousel/files-carousel.component';
import {
  FormFilesDropAreaComponent,
} from '@shared/standalone/file-controls/form-files-drop-area/form-files-drop-area.component';

interface IJewelryClaimCompleteDialogState {
  loadInProgress: boolean;
}

@Component({
  selector: 'app-jewelry-claim-complete-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    ThFormFieldBlockComponent,
    FormFieldComponent,
    FormInputComponent,
    AsyncPipe,
    FilesCarouselComponent,
    FormFilesDropAreaComponent,
    NgIf,
    LetDirective,
    FormsModule,
    ThFormFieldComponent,
    MatIconModule,
    LoadingButtonDirective,
    MaxValueDirective,
  ],
  templateUrl: './jewelry-claim-complete-dialog.component.html',
  styleUrls: ['./jewelry-claim-complete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState],
})
export class JewelryClaimCompleteDialogComponent {
  vm$ = this.state.select();
  salesTax: number = null;
  salesTaxMax: number = null;

  dialogData: {
    data: {
      claimId: number;
      serviceActionType: TServiceActionType.Repair | TServiceActionType.Replacement;
      authorizedAmount: number;
    };
  } = inject(MAT_DIALOG_DATA);
  filesForm: FormArray<FormControl<Files>> = this.fb.array<Files>([], [validateArrayMaxFilesSize()]);
  dropArea = {
    ...RECEIPT_FILES_DROP_AREA,
  };

  constructor(
    private readonly state: RxState<IJewelryClaimCompleteDialogState>,
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly claimApiService: ClaimApiService,
  ) {
    this.state.set({
      loadInProgress: false,
    });
    this.salesTaxMax = +(this.dialogData.data.authorizedAmount * 0.1).toFixed(2);
  }

  removeFiles(fileIndex: number): void {
    const updatedFiles = this.filesForm.value.filter(file => fileIndex !== file.index);
    this.filesForm.clear();
    updatedFiles.forEach(file => {
      this.filesForm.push(this.fb.control(file));
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  completeClaim(): void {
    this.state.set({
      loadInProgress: true,
    });
    this.claimApiService.markCompleted(this.dialogData.data.claimId, {
      salesTax: this.salesTax,
      receiptFiles: this.filesForm.value,
    }).subscribe(() => {
      this.state.set({
        loadInProgress: false,
      });
      this.dialogRef.close(true);
    });
  }
}
