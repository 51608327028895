import { Injectable } from '@angular/core';
import { PlansPageService } from '@th-common-retailer/shared/pages/plans/plans-page.service';

import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { Plan } from '@core/interfaces/plan/plan.interface';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { IAppTableCol } from '@shared/modules/table/interfaces/table-col.interface';
import { IAppTableRow } from '@shared/modules/table/interfaces/table-row.interface';
import { ThTableService } from '@shared/modules/table/table.service';

import { MjcPlanDialogsService } from '../../core/services/mjc-plan-dialogs.service';
import { MjcCreateClaimComponent } from '../../dialogs/mjc-create-claim/mjc-create-claim.component';
import { MjcRegisterPlanComponent } from '../../dialogs/mjc-register-plan/mjc-register-plan.component';


@Injectable({
  providedIn: 'root',
})
export class MjcPlansPageService extends PlansPageService {
  constructor(
    private readonly modalDialogService: ModalDialogService,
    private readonly mjcPlanDialogsService: MjcPlanDialogsService,
  ) {
    super();
  }

  openRegisterPlan(): void {
    this.modalDialogService.open({
      title: 'Register Plan',
    }, MjcRegisterPlanComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }

  openPlanDetailsDialog(planId: number, singleView: boolean = false): void {
    this.mjcPlanDialogsService.openPlanDetailsDialog(planId, singleView);
  }

  getDefaultColumns(): IAppTableCol[] {
    return [
      ThTableService.buildCol('name', 'Plan Number', {
        notHideable: true,
      }),
      ThTableService.buildCol('consumer', 'Consumer', {
        maxWidth: '200px',
      }),
      ThTableService.buildCol('parentPlanName', 'Parent Plan Name', {
        minWidth: '120px',
        maxWidth: '200px',
      }),
      ThTableService.buildCol('purchaseRetailerName', 'Retailer Name', {
        maxWidth: '200px',
      }),
      ThTableService.buildCol('purchaseDate', 'Purchase Date', {
        minWidth: '120px',
      }),
      ThTableService.buildCol('productName', 'Product Name'),
      ThTableService.buildCol('productDescription', 'Product Description', {
        maxWidth: '180px',
      }),
      ThTableService.buildCol('protectionPlanStatus', 'Plan Status'),
      ThTableService.buildCol('actions', 'Actions', {
        notHideable: true,
        disableSorting: true,
      }),
    ];
  }

  buildPlanRows(plans: Plan[]): IAppTableRow[] {
    return plans.map(item => ({
      id: ThTableService.buildCellInline(item.id.toString()),
      name: ThTableService.buildCellCustom({
        type: 'planName',
        plan: item,
      }),
      consumer: ThTableService.buildCellInline(item.consumer || '-'),
      parentPlanName: ThTableService.buildCellInline(item.parentPlanName || '-'),
      purchaseRetailerName: ThTableService.buildCellInline(item.purchaseRetailerName || '-'),
      purchaseDate: ThTableService.buildCellDate(item.purchaseDate || '-'),
      productName: ThTableService.buildCellInline(item.productName || '-'),
      productDescription: ThTableService.buildCellInline(item.productDescription || '-'),
      protectionPlanStatus: ThTableService.buildCellInline(this.planStatuses[item.protectionPlanStatus] || '-'),
      actions: ThTableService.buildCellCustom({
        type: 'actions',
        plan: item,
        showMjcPdfLink: !!item.name && !!item.mjcDocPostedOn,
      }),
    }));
  }

  startClaimDialog(planId: number): void {
    this.modalDialogService.open({
      title: 'Create Claim',
      data: {
        planId,
      },
    }, MjcCreateClaimComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }

  getDefaultHiddenColumns(): string[] {
    return ['productName'];
  }
}
