<div *rxLet="vm$; let vm">
  <app-wizard-form-section sectionTitle="Consumer">
    <app-consumer-template-form
      [formGroupRef]="consumerForm.get('info')"
      [phonesRequired]="phonesRequired"
      [useRadio]="false"></app-consumer-template-form>
  </app-wizard-form-section>
  <app-wizard-form-section sectionTitle="Consumer Address">
    <app-address-template-form [formGroup]="consumerForm.get('consumerAddress')"></app-address-template-form>
  </app-wizard-form-section>
  <div class="form-step-buttons">
    <button
      mat-flat-button
      (click)="goToProduct()"
      [disabled]="consumerForm.invalid">
      Next
    </button>
  </div>
</div>
