<div
  class="plan-info-wizard-step"
  *rxLet="vm$; let vm">
  <ng-container *formGroup="vm.planForm">
    <div formGroupName="coveredProduct">
      <div class="plan-info-wizard-step__header">
        <h2>Covered Product</h2>
        <button
          class="plan-info-wizard-step__remove-btn"
          color="warn"
          mat-stroked-button
          *ngIf="vm.plansLength > 1"
          (click)="removePlan()">
          <mat-icon>delete</mat-icon>
          Remove
        </button>
      </div>
      <th-form-field-block>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-mjc-product-type-select
            formControlName="type"
            (productTypeChanged)="productTypeChanged($event)"></app-mjc-product-type-select>
        </th-form-field>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-form-input
            formControlName="productName"
            [label]="'Product Short Name'"></app-form-input>
        </th-form-field>
      </th-form-field-block>
      <th-form-field-block>
        <th-form-field
          *ngIf="vm.planForm.value.coveredProduct.type === TProductType.Watch"
          [size]="TCommonFieldSize.Medium">
          <app-manufacturer-select
            formControlName="crmManufacturerId"
            [selectedManufacturer]="vm.selectedManufacturer"
            (selectedItem)="manufacturerSelected($event)"></app-manufacturer-select>
        </th-form-field>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-form-input
            formControlName="sku"
            [label]="'SKU'"></app-form-input>
        </th-form-field>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-form-input
            formControlName="unitPrice"
            [dataType]="'price'"
            [label]="'Purchase Price of Product'"></app-form-input>
        </th-form-field>
      </th-form-field-block>
      <th-form-field-block>
        <th-form-field [size]="TCommonFieldSize.Half">
          <app-form-textarea
            formControlName="productDescription"
            label="Detailed Product Description"></app-form-textarea>
        </th-form-field>
      </th-form-field-block>
    </div>
    <app-wizard-form-section
      sectionTitle="Plan"
      formGroupName="info">
      <th-form-field-block>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-retailer-select
            formControlName="retailerId"
            [disabled]="!vm.planForm.value.coveredProduct.type"
            [label]="'Retailer'"
            [selectedRetailer]="vm.selectedRetailer"
            (selectedItem)="retailerSelected($event)"></app-retailer-select>
        </th-form-field>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-form-select
            [data]="vm.plansList"
            [loading]="vm.plansLoading"
            titleField="parentPlanName"
            formControlName="planId"
            [disabled]="!vm.planForm.value.coveredProduct.type"
            [label]="'Plan'"
            (itemSelected)="planSelected($event)"></app-form-select>
        </th-form-field>
        <app-form-input
          hidden
          formControlName="planName"></app-form-input>
      </th-form-field-block>
      <th-form-field-block>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-form-input
            formControlName="planPrice"
            [disabled]="!vm.planForm.value.coveredProduct.type"
            [dataType]="'price'"
            [label]="'Purchase Price of Plan'"></app-form-input>
        </th-form-field>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-form-input
            formControlName="receiptNumber"
            [disabled]="!vm.planForm.value.coveredProduct.type"
            [label]="'Sales Invoice Number'"></app-form-input>
        </th-form-field>
        <th-form-field [size]="TCommonFieldSize.Medium">
          <app-form-date
            formControlName="purchaseDate"
            [disabled]="!vm.planForm.value.coveredProduct.type"
            [label]="'Purchase Date'"
            [max]="maxPurchaseDate"
            [min]="minPurchaseDate$ | push"></app-form-date>
        </th-form-field>
      </th-form-field-block>
    </app-wizard-form-section>
    <app-wizard-form-section sectionTitle="Photos and Documents">
      <app-files-carousel
        *ngIf="vm.planForm.get('files').value.length > 0"
        [files]="vm.planForm.get('files').value"
        [showRemove]="true"
        [showDocumentName]="true"
        (removeFileByIndex)="removeFileIndex(vm.planForm.get('files'), $event)"></app-files-carousel>
      <th-form-field-block>
        <ng-container *ngFor="let dropArea of dropAreas">
          <th-form-field [size]="dropArea.sectionSize">
            <app-form-files-drop-area
              [formFilesArray]="vm.planForm.get('files')"
              [labelDescription]="dropArea.labelDescription"
              [labelDescriptionForMobile]="dropArea.labelDescriptionForMobile"
              [multiple]="dropArea.maxFilesNumber === null"
              [pictureIcon]="dropArea.svgIcon"
              [maxFilesNumber]="dropArea.maxFilesNumber"
              [docType]="dropArea.docType"
              [oneFileErrorMessage]="dropArea.errorMessage"></app-form-files-drop-area>
          </th-form-field>
        </ng-container>
      </th-form-field-block>
    </app-wizard-form-section>
  </ng-container>
  <div class="form-step-buttons">
    <button
      mat-flat-button
      color="primary"
      (click)="goBack()">
      Back
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="vm.planForm.invalid"
      (click)="addAnotherPlan()">
      Add another plan
    </button>
    <button
      mat-flat-button
      color="primary"
      [disabled]="vm.planForm.invalid"
      (click)="goNext()">
      Next
    </button>
  </div>
</div>
