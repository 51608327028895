import { TTileId } from '@core/enums/claim/tile-types.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { DashboardCompositeTile, DashboardTile } from '@core/interfaces/dashboard-tile.interface';

import {
  ACTION_TILE_SEARCH_REQUEST,
  COMPOSITE_TILE_SEARCH_REQUESTS,
  MjcTileFilter,
  STAT_TILE_SEARCH_REQUEST,
} from '../../../core/constants/mjc-tile-stage-filter.constant';

export namespace StoreState {
  export interface IStoreState {
    claimsTotal: number;
    tiles: DashboardCompositeTile[];
    actionTiles: DashboardTile[];
    statTiles: DashboardTile[];
  }

  export const initialState: IStoreState = {
    claimsTotal: 0,
    actionTiles: [
      {
        id: TTileId.ClaimsToReview,
        title: 'Claims to Review',
        icon: 'flag-white',
        searchRequest: ACTION_TILE_SEARCH_REQUEST[TTileId.ClaimsToReview],
        claimType: null,
        // features: [Feature.HasClaimsForClientReview],
        features: [],
        tileDataType: 'claims',
        count: 0,
        loading: true,
      },
    ],
    statTiles: [
      {
        id: TTileId.MjcRepairs,
        title: 'Open Repairs',
        icon: 'parts',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.MjcRepairs],
        claimType: null,
        features: [],
        tileDataType: 'serviceActions',
        count: 0,
        loading: true,
      },
      {
        id: TTileId.MjcReplacements,
        title: 'Open Replacements',
        icon: 'exchanges',
        searchRequest: STAT_TILE_SEARCH_REQUEST[TTileId.MjcReplacements],
        claimType: null,
        features: [],
        tileDataType: 'serviceActions',
        count: 0,
        loading: true,
      },
    ],
    tiles: [
      {
        id: MjcTileFilter.getActionTypeTileStageKey(TServiceActionType.Repairs),
        title: 'Repairs',
        icon: 'parts',
        searchRequest:
          COMPOSITE_TILE_SEARCH_REQUESTS[
            MjcTileFilter.getActionTypeTileStageKey(TServiceActionType.Repairs)
          ],
        stages: [],
        totalStages: 0,
        requiredStages: 0,
        loading: true,
        serviceActionType: TServiceActionType.Repairs,
        features: [],
        tileDataType: 'serviceActions',
      },
      {
        id: MjcTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement),
        title: 'Replacements',
        icon: 'exchanges',
        searchRequest:
          COMPOSITE_TILE_SEARCH_REQUESTS[
            MjcTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement)
          ],
        stages: [],
        totalStages: 0,
        requiredStages: 0,
        loading: true,
        serviceActionType: TServiceActionType.Replacement,
        features: [],
        tileDataType: 'serviceActions',
      },
    ],
  };
}
