import { Injectable } from '@angular/core';
import { finalize, switchMap, take } from 'rxjs/operators';

import { BaseStore } from '../_base/base.store';
import IStoreState = StoreState.IStoreState;
import { ManufacturerApi } from '../../api/manufacturer.api';
import { StoreSearchRequest } from '../../models/search/search-request.model';
import { StoreState } from './manufacturerList.state';
import initialState = StoreState.initialState;

@Injectable()
export class ManufacturerListStore extends BaseStore<IStoreState> {
  constructor(private readonly manufacturerApi: ManufacturerApi) {
    super(initialState);
  }

  init(pageSize: number = 10) {
    const searchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...searchRequest,
        pageSize,
      },
    });
  }

  loadData(lazy: boolean = false) {
    this.updateState({ loading: true });
    this.select$('searchRequest')
      .pipe(
        take(1),
        switchMap(searchRequest => this.manufacturerApi.all(searchRequest)),
        finalize(() => this.updateState({ loading: false })),
      )
      .subscribe(({ items, totalCount, page, pageCount }) => {
        this.updateState({
          items: lazy ? this.get().items.concat(items || []) : items || [],
          pagination: {
            totalCount,
            page,
            pageCount,
          },
        });
      });
  }

  updateSearchRequest(searchRequest: Partial<StoreSearchRequest>) {
    const storeSearchRequest = this.get('searchRequest');
    this.updateState({
      searchRequest: {
        ...storeSearchRequest,
        ...searchRequest,
      },
    });
  }
}
