<app-form-select [formControl]="formControl"
                 [data]="items$ | push"
                 [loading]="loading$ | push"
                 [pagination]="pagination$ | push"
                 [label]="label"
                 [selectedItem]="selectedManufacturer"
                 [required]="required"
                 [showSearch]="true"
                 [loadOnScroll]="true"
                 (selectSearch)="manufacturerSearch($event)"
                 (itemSelected)="manufacturerSelected($event)"
                 (selectLoadNextPage)="loadNextPage($event)"></app-form-select>

