import { ChangeDetectionStrategy, Component, inject, Input, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { IRepairAction } from '../../../../core/interfaces/repair-service-action.interface';
import {
  RepairServiceActionDialogComponent,
} from '../../dialogs/repair-service-action-dialog/repair-service-action-dialog.component';
import { MjcCreateClaimStore } from '../../store/mjc-create-claim.store';

@Component({
  selector: 'app-mjc-repair-action-form-actions-cell',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, RepairServiceActionDialogComponent],
  templateUrl: './mjc-repair-action-form-actions-cell.component.html',
  styleUrls: ['./mjc-repair-action-form-actions-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MjcRepairActionFormActionsCellComponent {
  @Input() repairAction: IRepairAction;
  @Input() repairActionIndex: number;
  store = inject(MjcCreateClaimStore);

  editRepairAction(
    repairActionDialogTemplate: TemplateRef<RepairServiceActionDialogComponent>,
  ): void {
    this.store.openRepairActionDialog({
      repairAction: this.repairAction,
      product: this.store.form.value.product,
    }, repairActionDialogTemplate, {
      title: 'Edit Repair Action',
      actionBtnLabel: 'Save',
    }).afterClosed().subscribe(result => {
      if (result?.repairAction) {
        this.store.updateRepairAction(result.repairAction, this.repairActionIndex);
      }
    });
  }

  deleteRepairAction(): void {
    this.store.deleteRepairAction(this.repairActionIndex);
  }
}
