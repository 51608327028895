import { inject, Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceActionApi } from '@core/api/service-action-api';
import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { DashboardCompositeTile } from '@core/interfaces/dashboard-tile.interface';
import { ClaimSearchRequest } from '@core/models/search/search-request.model';


interface ITileStageFilter {
  getActionTypeTileStageKey(serviceActionType: TServiceActionType, serviceActionTileStage?: TServiceActionTileStage): string;
}

@Injectable({
  providedIn: 'root',
})
export class CompositeTileService {
  serviceActionApi = inject(ServiceActionApi);
  notRequiredStages: TServiceActionTileStage[] = [TServiceActionTileStage.ExpiringSoon];

  requestCompositeTile(
    compositeTileSearchRequests: {
      [key: string]: Partial<ClaimSearchRequest>;
    },
    tileStageFilter: ITileStageFilter,
    serviceActionType: TServiceActionType,
    serviceActionStages: TServiceActionTileStage[],
  ): Observable<{ totalCount: number }[]> {
    return zip(
      ...serviceActionStages.map(serviceActionStage => {
        const { serviceActionFilters, ...params } =
          compositeTileSearchRequests[
            tileStageFilter.getActionTypeTileStageKey(serviceActionType, serviceActionStage)
          ];

        return zip(
          ...serviceActionFilters.map(partOrderServiceActionFilter => this.serviceActionApi.all(
            {
              ...params,
              ...partOrderServiceActionFilter,
              noRows: true,
            },
            {
              tileId: `${TServiceActionType[serviceActionType]}${TServiceActionTileStage[serviceActionStage]}`,
            },
          )),
        ).pipe(
          map(counts => ({
            totalCount: counts.reduce((sum, stage) => sum + stage.totalCount, 0),
          })),
        );
      }),
    ).pipe(
      map(stages => stages.filter(
        (stage, index) =>
          !this.notRequiredStages.includes(serviceActionStages[index]) || stage.totalCount > 0),
      ),
    );
  }

  parseCompositeTile(
    tile: DashboardCompositeTile,
    stages: {totalCount: number}[],
    serviceActionStages: TServiceActionTileStage[],
  ): DashboardCompositeTile {
    return {
      ...tile,
      stages: stages.map((stage, index) => ({
        count: stage.totalCount,
        isRequired: !this.notRequiredStages.includes(serviceActionStages[index]),
        serviceActionTileStage: serviceActionStages[index],
      })),
      loading: false,
      totalStages: stages
        .filter((stage, index) => !this.notRequiredStages.includes(serviceActionStages[index]))
        .reduce((sum, stage) => sum + stage.totalCount, 0),
      requiredStages: stages
        .filter((stage, index) => !this.notRequiredStages.includes(serviceActionStages[index]))
        .reduce((sum, stage) => sum + stage.totalCount, 0),
    };
  }
}
