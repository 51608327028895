import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

import { BaseFormControlElement } from '@core/base/base-form-control-element';
import { IIdentityString } from '@core/interfaces/util/identity.interface';
import { ManufacturerListStore } from '@core/store/manufacturer/manufacturerList.store';

@Component({
  selector: 'app-manufacturer-select',
  templateUrl: './manufacturer-select.component.html',
  styleUrls: ['./manufacturer-select.component.scss'],
  providers: [ManufacturerListStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerSelectComponent extends BaseFormControlElement implements OnInit {
  @Input() label = 'Manufacturer';
  @Input() required: boolean;
  @Input() selectedManufacturer: IIdentityString;
  @Output() selectedItem = new EventEmitter<IIdentityString>();

  items$ = this.store.select$('items');
  loading$ = this.store.select$('loading');
  pagination$ = this.store.select$('pagination');

  constructor(@Optional() @Self() public ngControl: NgControl, private readonly store: ManufacturerListStore) {
    super(ngControl);
    this.store.loadData();
  }

  loadNextPage(page: number): void {
    this.store.updateSearchRequest({
      page,
    });
    this.store.loadData(true);
  }

  manufacturerSelected(manufacturer: IIdentityString): void {
    this.selectedItem.emit(manufacturer);
  }

  manufacturerSearch(searchString: string): void {
    this.store.updateSearchRequest({
      searches: [searchString],
    });
    this.store.loadData();
  }
}
