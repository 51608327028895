import { Injectable, Type } from '@angular/core';

import { IWizardNavigation } from '@core/interfaces/wizard-navigation.interface';

import { StoreState } from './wizard-navigation.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;

import { Observable, Subject } from 'rxjs';

import { BaseStore } from '@core/store/_base/base.store';

@Injectable()
export class WizardNavigationStore<TNavigationStep, TNavigationChildrenStep = unknown> extends BaseStore<
IStoreState<TNavigationStep, TNavigationChildrenStep>
> {
  stepChanged$$ = new Subject<void>();
  stepChanged$ = this.stepChanged$$.asObservable();

  constructor() {
    super(initialState as IStoreState<TNavigationStep, TNavigationChildrenStep>);
  }

  selectNavigationList$(): Observable<IWizardNavigation<TNavigationStep, TNavigationChildrenStep>[]> {
    return this.select$('navigationList');
  }

  getNavigationList(): IWizardNavigation<TNavigationStep, TNavigationChildrenStep>[] {
    return this.get('navigationList');
  }

  setNavigationList(navigationList: IWizardNavigation<TNavigationStep, TNavigationChildrenStep>[]): void {
    this.updateState({
      navigationList,
    });
  }

  setStep(activeStep: TNavigationStep | TNavigationChildrenStep): void {
    const currentStep = this.get('activeStep');
    const currentStepRef = this._getStep(currentStep);
    if (currentStepRef) {
      currentStepRef.active = false;
    }
    const activeStepRef = this._getStep(activeStep);
    activeStepRef.active = true;
    this.updateState(state => ({
      ...state,
      activeStep: activeStepRef.id,
      activeComponent: activeStepRef.component,
    }));
    this.stepChanged$$.next();
  }

  nextStep(): void {
    const navigationList = this._flatNavigation();
    const currentStep = this.get('activeStep');
    const activeStep = navigationList[navigationList.findIndex(navItem => navItem.id === currentStep) + 1].id;
    this.setStep(activeStep);
  }

  stepBack(): void {
    const navigationList = this._flatNavigation();
    const currentStep = this.get('activeStep');
    const activeStep = navigationList[navigationList.findIndex(navItem => navItem.id === currentStep) - 1].id;
    this.setStep(activeStep);
  }

  selectActiveComponent$(): Observable<Type<unknown>> {
    return this.select$('activeComponent');
  }

  selectActiveStep$(): Observable<TNavigationStep | TNavigationChildrenStep> {
    return this.select$('activeStep');
  }

  getActiveStep(): TNavigationStep | TNavigationChildrenStep {
    return this.get('activeStep');
  }

  private _getActiveComponent(activeStep: TNavigationStep | TNavigationChildrenStep): Type<any> {
    const stepComponents = this._flatNavigation();
    return stepComponents.find(navItem => navItem.id === activeStep).component;
  }

  private _getStep(activeStep: TNavigationStep | TNavigationChildrenStep): IWizardNavigation<TNavigationStep | TNavigationChildrenStep> {
    const steps = this._flatNavigation();
    return steps.find(navItem => navItem.id === activeStep);
  }

  private _flatNavigation(): IWizardNavigation<TNavigationStep | TNavigationChildrenStep>[] {
    return this.get('navigationList')
      .map(navItem => [navItem,
        ...(navItem.children || []).map(child => child),
      ])
      .flat();
  }
}
