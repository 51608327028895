import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { SwitchComponent } from '@shared/components/switch/switch.component';
import { ADDRESS_SUGGEST_SCROLL_SELECTOR } from '@shared/modules/claim-dialogs/components/address-suggest/address-suggest.token';
import {
  AddressTemplateFormComponent,
} from '@shared/standalone/claim-forms/address-template-form/address-template-form.component';
import {
  ConsumerTemplateFormComponent,
} from '@shared/standalone/claim-forms/consumer-template-form/consumer-template-form.component';
import { WizardFormSectionComponent } from '@shared/wizard/wizard-form-section/wizard-form-section.component';

import { MjcCreateClaimStore } from '../../store/mjc-create-claim.store';

interface IMjcConsumerWizardState {
  addressValidationInProgress: boolean;
}

@Component({
  selector: 'app-mjc-consumer-wizard',
  standalone: true,
  imports: [
    CommonModule,
    AddressTemplateFormComponent,
    ConsumerTemplateFormComponent,
    LetDirective,
    MatButtonModule,
    SwitchComponent,
    WizardFormSectionComponent,
  ],
  templateUrl: './mjc-consumer-wizard.component.html',
  styleUrls: ['./mjc-consumer-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ADDRESS_SUGGEST_SCROLL_SELECTOR,
      useValue: '.create-claim__content',
    },
  ],
})
export class MjcConsumerWizardComponent {
  store = inject(MjcCreateClaimStore);
  state = inject(RxState<IMjcConsumerWizardState>);
  consumerForm = this.store.form.get('consumer');
  phonesRequired = this.store.phonesRequired;

  vm$ = this.state.select();

  constructor() {
    this.state.set({
      addressValidationInProgress: false,
    });
  }

  setInProgressState(addressValidationInProgress: boolean): void {
    this.state.set({
      addressValidationInProgress,
    });
  }

  goToProduct(): void {
    this.store.goNext();
  }
}
