import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-consumer-default-search-for-options',
  standalone: true,
  imports: [],
  templateUrl: './consumer-default-search-for-options.component.html',
  styleUrls: ['./consumer-default-search-for-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumerDefaultSearchForOptionsComponent {

}
