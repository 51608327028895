<div [formGroup]="formGroup">
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Large">
      <app-form-input
        *appReadonlyControl="
          formGroup.get('streetAddress1').enabled;
          else labelValueItem;
          label: 'Address';
          value: formGroup.get('streetAddress1').getRawValue();
          let label = label
        "
        [autocomplete]="'section-address consumer address-line1'"
        [formControlName]="'streetAddress1'"
        [label]="'Address'"></app-form-input>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-input
        *appReadonlyControl="
          formGroup.get('streetAddress2').enabled;
          else labelValueItem;
          label: 'Address 2';
          value: formGroup.get('streetAddress2').getRawValue();
          let label = label
        "
        [autocomplete]="'section-address consumer address-line2'"
        [formControlName]="'streetAddress2'"
        [label]="'Address 2'"></app-form-input>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-input
        *appReadonlyControl="
          formGroup.get('city').enabled;
          else labelValueItem;
          label: 'City';
          value: formGroup.get('city').getRawValue();
          let label = label
        "
        [autocomplete]="'section-address consumer address-level2'"
        [formControlName]="'city'"
        [label]="'City'"></app-form-input>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-select-state
        *appReadonlyControl="
          formGroup.get('stateProvince').enabled;
          else labelValueItem;
          label: 'State';
          value: formGroup.get('stateProvince').getRawValue();
          let label = label
        "
        [formControlName]="'stateProvince'"
        [showOutOfScopeValue]="true"
        [label]="'State'"></app-select-state>
    </th-form-field>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-input
        *appReadonlyControl="
          formGroup.get('postalCode').enabled;
          else labelValueItem;
          label: 'Zip';
          value: formGroup.get('postalCode').getRawValue();
          let label = label
        "
        [formControlName]="'postalCode'"
        [label]="'Zip'"></app-form-input>
    </th-form-field>
  </th-form-field-block>
</div>
<ng-template
  #labelValueItem
  let-label="label"
  let-value="value">
  <app-label-value-item
    [label]="label"
    [value]="value"></app-label-value-item>
</ng-template>
