import { AsyncPipe, NgComponentOutlet, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Type } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';

import { IWizardNavigation } from '@core/interfaces/wizard-navigation.interface';
import { DialogService } from '@core/services/dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { WIZARD_STORE } from '@core/store/wizard/wizard.store';
import { WizardNavigationStore } from '@core/store/wizard/wizard-navigation.store';
import { AlertMessageComponent } from '@shared/components/alert-message/alert-message.component';
import { SideDialogWithRouteComponent } from '@shared/components/side-dialog-with-route/side-dialog-with-route.component';
import { ClaimFormSteps } from '@shared/modules/claim-dialogs/enums/claim-form-steps.enum';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';
import { WizardNavComponent } from '@shared/wizard/wizard-nav/wizard-nav.component';

// eslint-disable-next-line max-len
import { PlanConsumerWizardStepComponent } from './containers/plan-consumer-wizard-step/plan-consumer-wizard-step.component';
import { PlanInfoWizardStepComponent } from './containers/plan-info-wizard-step/plan-info-wizard-step.component';
import { PlanSummaryWizardStepComponent } from './containers/plan-summary-wizard-step/plan-summary-wizard-step.component';
import { PlanWizardStore, TPlanWizardChildSteps, TPlanWizardSteps } from './store/plan-wizard.store';

interface IPlanWizardState {
  isVisible: boolean;
  submitInProgress: boolean;
  navigationList: IWizardNavigation<TPlanWizardSteps, TPlanWizardChildSteps>[];
  activeStep: any;
  activeComponent: Type<any>;
  purchaseDateAlertMessage: string;
  showAlertMessage: boolean;
}

@Component({
  selector: 'app-mjc-register-plan',
  templateUrl: './mjc-register-plan.component.html',
  styleUrls: ['./mjc-register-plan.component.scss'],
  standalone: true,
  imports: [
    CustomOverlayScrollbarDirective,
    MatIconModule,
    PushPipe,
    NgIf,
    NgComponentOutlet,
    NgForOf,
    SideDialogWithRouteComponent,
    SideDialogAnimationComponent,
    MatButtonModule,
    LetDirective,
    LoaderModule,
    WizardNavComponent,
    AlertMessageComponent,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
    {
      provide: WIZARD_STORE,
      useExisting: PlanWizardStore,
    },
    WizardNavigationStore,
    PlanWizardStore,
    SideDialogStateService,
  ],
})
export class MjcRegisterPlanComponent {
  vm$ = this.state.select();
  dialogState = inject(SideDialogStateService);
  steps = ClaimFormSteps;

  constructor(
    private readonly store: PlanWizardStore,
    private readonly state: RxState<IPlanWizardState>,
    private readonly dialogService: DialogService,
  ) {
    this.state.connect('isVisible', this.dialogState.isVisible$);
    this.state.connect('submitInProgress', this.store.select$('submitInProgress'));
    this.state.connect('navigationList', this.store.ws.select$('navigationList'));
    this.state.connect('activeStep', this.store.ws.select$('activeStep'));
    this.state.connect('activeComponent', this.store.ws.select$('activeComponent'));
    this.state.connect('purchaseDateAlertMessage', this.store.select$('purchaseDateAlertMessage'));
    this.state.connect('showAlertMessage', this.store.select$('showAlertMessage'));
    this.store.setNavigationList([
      {
        id: 'consumer',
        title: 'Consumer',
        valid$: this.store.form.get('consumer').statusChanges.pipe(
          startWith(this.store.form.get('consumer').status),
          distinctUntilChanged(),
          map(status => status === 'VALID'),
        ),
        component: PlanConsumerWizardStepComponent,
        active: false,
      },
      {
        id: 'products',
        title: 'Products',
        valid$: this.store.form.get('plans').statusChanges.pipe(
          startWith(this.store.form.get('consumer').status),
          distinctUntilChanged(),
          map(status => status === 'VALID'),
        ),
        component: null,
        active: false,
        children: [
          {
            id: 'product0',
            title: 'Product 1',
            valid$: (this.store.form.get('plans') as FormArray).at(0).statusChanges.pipe(
              distinctUntilChanged(),
              map(status => status === 'VALID'),
            ),
            component: PlanInfoWizardStepComponent,
            active: false,
          },
        ],
      },
      {
        id: 'summary',
        title: 'Summary',
        valid$: this.store.form.statusChanges.pipe(
          startWith(this.store.form.status),
          distinctUntilChanged(),
          map(status => status === 'VALID'),
        ),
        component: PlanSummaryWizardStepComponent,
        active: false,
      },
    ]);
    this.store.ws.setStep('consumer');

    this.store.select$('closeDialog').pipe(
      filter(Boolean),
      take(1),
    ).subscribe(() => {
      this.close();
    });
  }

  close(): void {
    this.dialogService.canLeaveDefaultConfirmationObservable(this.store.form).subscribe(canLeave => {
      if (canLeave) {
        this.dialogState.close();
      } else {
        return;
      }
    });
  }
}
