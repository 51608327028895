import { importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { PlanDetailsDialogService } from '@th-common-retailer/shared/dialogs/plan-details/plan-details-dialog.service';
import { ClaimsPageService } from '@th-common-retailer/shared/pages/claims/claims-page.service';
import { PlansPageService } from '@th-common-retailer/shared/pages/plans/plans-page.service';

import { CoreModule } from '@core/core.module';
import { MainHub } from '@core/services/hubs/main.hub';
import { NavigationService } from '@core/services/navigation.service';
import { PlanService } from '@core/services/plan.service';
import { PipesModule } from '@shared/pipes/pipes.module';

import { MJC_APP_ROUTES } from './app.routes';
import { MjcMainHub } from './core/services/mjc-main.hub';
import { MJCNavigationService } from './core/services/mjc-navigation.service';
import { MjcClaimsPageService } from './pages/claims/mjc-claims-page.service';
import { MjcPlanDetailsDialogService } from './pages/plans/dialogs/mjc-plan-details-dialog.service';
import { MjcPlansPageService } from './pages/plans/mjc-plans-page.service';

export const MJC_APP = {
  providers: [
    importProvidersFrom(
      CoreModule,
      PipesModule,
    ),
    provideRouter(
      MJC_APP_ROUTES,
      // withPreloading(PreloadAllModules),
      // withDebugTracing(),
    ),
    provideAnimations(),
    {
      provide: MainHub,
      useClass: MjcMainHub,
    },
    {
      provide: NavigationService,
      useClass: MJCNavigationService,
    },
    {
      provide: PlansPageService,
      useExisting: MjcPlansPageService,
    },
    {
      provide: ClaimsPageService,
      useExisting: MjcClaimsPageService,
    },
    {
      provide: PlanDetailsDialogService,
      useExisting: MjcPlanDetailsDialogService,
    },
    PlanService,
  ],
};
