import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { tap } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { LookupApiService } from '@core/api/lookup-api.service';
import { Feature } from '@core/enums/feature.enum';
import { IIdentity } from '@core/interfaces/util/identity.interface';
import { FeatureService } from '@core/services/feature.service';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { ThBaseFormControlElement } from '@shared/form/base-form-control-element.directive';

import { TProductType } from '../../../../core/enums/product-type.enum';

interface IMjcProductTypeSelectState {
  productTypes: IIdentity[];
  loading: boolean;
}

@Component({
  selector: 'app-mjc-product-type-select',
  standalone: true,
  imports: [FormSelectComponent, LetDirective, ReactiveFormsModule],
  templateUrl: './mjc-product-type-select.component.html',
  styleUrls: ['./mjc-product-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MjcProductTypeSelectComponent extends ThBaseFormControlElement<number> {
  @Output() productTypeChanged = new EventEmitter<IIdentity>();
  vm$ = this.state.select();

  constructor(
    private readonly state: RxState<IMjcProductTypeSelectState>,
    private readonly lookupApiService: LookupApiService,
    private readonly featureService: FeatureService,
  ) {
    super();
    this.state.set({
      loading: true,
    });
    this.state.connect('productTypes', this.lookupApiService.getMjcProductTypes().pipe(
      map(productTypes => productTypes.filter(productType => {
        if (productType.id === TProductType.Watch) {
          return this.featureService.allowed(Feature.HasWatchPlans);
        } else if (productType.id === TProductType.Jewelry) {
          return this.featureService.allowed(Feature.HasJewelryPlans);
        } else {
          return true;
        }
      })),
      tap(productTypes => {
        if (productTypes.length === 1 && this.isRequired) {
          this.formControl.setValue(productTypes[0].id);
          this.productTypeChanged.emit(productTypes[0]);
        }
      }),
      finalize(() => this.state.set({ loading: false })),
    ));
  }
}
