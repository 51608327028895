import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AsyncPipe, NgComponentOutlet, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RxState } from '@rx-angular/state';
import { RxIf } from '@rx-angular/template/if';
import { LetDirective } from '@rx-angular/template/let';
import { PushPipe } from '@rx-angular/template/push';
import { combineLatestWith } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';

import { IWizardNavigation } from '@core/interfaces/wizard-navigation.interface';
import { DialogService } from '@core/services/dialog.service';
import { SideDialogStateService } from '@core/services/dialog/side-dialog-state.service';
import { WIZARD_STORE } from '@core/store/wizard/wizard.store';
import { WizardNavigationStore } from '@core/store/wizard/wizard-navigation.store';
import { ClaimFormSteps } from '@shared/modules/claim-dialogs/enums/claim-form-steps.enum';
import { LoaderModule } from '@shared/modules/loader/loader.module';
import { CustomOverlayScrollbarDirective } from '@shared/standalone/custom-overlay-scrollbar.directive';
import { SideDialogAnimationComponent } from '@shared/standalone/side-dialog-animation/side-dialog-animation.component';

import {
  MjcClaimServiceActionsWizardComponent,
} from './containers/mjc-claim-service-actions-wizard/mjc-claim-service-actions-wizard.component';
import { MjcConsumerWizardComponent } from './containers/mjc-consumer-wizard/mjc-consumer-wizard.component';
import {
  MjcProductIncidentWizardComponent,
} from './containers/mjc-product-incident-wizard/mjc-product-incident-wizard.component';
import { MjcCreateClaimStore, TMjcClaimWizardSteps } from './store/mjc-create-claim.store';

interface ICreateClaimState {
  isLoading: boolean;
  navigationList: IWizardNavigation<TMjcClaimWizardSteps>[];
  activeComponent: Type<any>;
  planName: string;
}

@Component({
  selector: 'app-mjc-create-claim',
  templateUrl: './mjc-create-claim.component.html',
  styleUrls: ['./mjc-create-claim.component.scss'],
  standalone: true,
  imports: [
    CustomOverlayScrollbarDirective,
    SideDialogAnimationComponent,
    MatIconModule,
    MatButtonModule,
    LetDirective,
    LoaderModule,
    NgIf,
    NgForOf,
    PushPipe,
    NgComponentOutlet,
    RxIf,
    FormsModule,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RxState,
    {
      provide: WIZARD_STORE,
      useExisting: MjcCreateClaimStore,
    },
    WizardNavigationStore,
    MjcCreateClaimStore,
    SideDialogStateService,
  ],
})
export class MjcCreateClaimComponent {
  vm$ = this.state.select();
  activeStep$ = this.store.wizardNavigationStore.select$('activeStep');
  steps = ClaimFormSteps;
  dialogData: {data: { planId: number }} = inject(DIALOG_DATA);

  constructor(
    private readonly state: RxState<ICreateClaimState>,
    private readonly store: MjcCreateClaimStore,
    private readonly sideDialogStateService: SideDialogStateService,
    private readonly dialogService: DialogService,
  ) {
    this.state.connect('activeComponent', this.store.wizardNavigationStore.select$('activeComponent'));
    this.state.connect('isLoading', this.store.select$('isLoading'));
    this.state.connect('navigationList', this.store.selectNavigationList$());
    this.store.initForm(this.dialogData.data.planId).subscribe(planDetails => {
      this.state.set({
        planName: this.store.form.get('plan.name').getRawValue(),
      });
      this.store.setDefaultServicingRetailer(planDetails.retailer);
      this.store.setNavigationList([
        {
          id: 'consumer',
          title: 'Consumer',
          valid$: this.store.form.get('consumer').statusChanges.pipe(
            startWith(this.store.form.get('consumer').status),
            distinctUntilChanged(),
            map(status => status === 'VALID'),
          ),
          component: MjcConsumerWizardComponent,
        },
        {
          id: 'product',
          title: 'Product Incident',
          valid$: this.store.form.get('product').statusChanges.pipe(
            combineLatestWith(this.store.form.get('files').statusChanges),
            startWith([
              this.store.form.get('product').status,
              this.store.form.get('files').status,
            ]),
            distinctUntilChanged(),
            map(
              ([productStatus, damageFileStatus]) =>
                productStatus === 'VALID' && damageFileStatus === 'VALID',
            ),
          ),
          component: MjcProductIncidentWizardComponent,
          children: [],
        },
        {
          id: 'serviceActions',
          title: 'Service Actions',
          valid$: this.store.form.get('replacementAction').statusChanges.pipe(
            startWith(this.store.form.get('replacementAction').status),
            distinctUntilChanged(),
            combineLatestWith(this.store.repairActionsForm.statusChanges.pipe(
              startWith(this.store.repairActionsForm.status),
              distinctUntilChanged(),
            )),
            map(
              ([replacementActionStatus, repairActionsStatus]) =>
                replacementActionStatus === 'VALID' || repairActionsStatus === 'VALID',
            ),
          ),
          component: MjcClaimServiceActionsWizardComponent,
          children: [],
        },
      ]);
      this.store.wizardNavigationStore.setStep('consumer');
    });
    this.store.select$('claimSubmitted').pipe(
      filter(Boolean),
      take(1),
    ).subscribe(() => {
      this.close();
    });
  }

  close(): void {
    this.dialogService.canLeaveDefaultConfirmationObservable(this.store.form).subscribe(canLeave => {
      if (canLeave) {
        this.sideDialogStateService.close();
      } else {
        return;
      }
    });
  }
}
