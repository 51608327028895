import { CurrencyPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { IIdentity } from '@core/interfaces/util/identity.interface';
import { formViewProvider } from '@core/providers/form-view.provider';
import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { FormTextareaComponent } from '@shared/components/form-textarea/form-textarea.component';
import { MaxLengthDirective } from '@shared/directives/validators/max-length.directive';
import { MaxValueDirective } from '@shared/directives/validators/max-value/max-value.directive';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { PipesModule } from '@shared/pipes/pipes.module';
import { LabelValueItemComponent } from '@shared/standalone/label-value-item/label-value-item.component';

import { TRepairCalculationType } from '../../../../core/enums/repair-calculation-type.enum';
import { TServicerType } from '../../../../core/enums/servicing-type.enum';
import {
  IRepairAction, IRepairType,
} from '../../../../core/interfaces/repair-service-action.interface';
import { IProductFormModel } from '../../interfaces/claim-form.model';
import { RepairTypeCategorySelectComponent } from '../repair-type-category-select/repair-type-category-select.component';
import { RepairTypeSelectComponent } from '../repair-type-select/repair-type-select.component';

@Component({
  selector: 'app-mjc-repair-service-action-form',
  standalone: true,
  templateUrl: './mjc-repair-service-action-form.component.html',
  styleUrls: ['./mjc-repair-service-action-form.component.scss'],
  imports: [
    FormDateComponent,
    FormInputComponent,
    FormSelectComponent,
    FormTextareaComponent,
    ReactiveFormsModule,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
    NgIf,
    FormsModule,
    RepairTypeCategorySelectComponent,
    RepairTypeSelectComponent,
    LetDirective,
    PipesModule,
    LabelValueItemComponent,
    CurrencyPipe,
    MaxValueDirective,
    MaxLengthDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [formViewProvider],
  providers: [RxState],
})
export class MjcRepairServiceActionFormComponent {
  @Input() repairAction: IRepairAction;
  @Input() product: IProductFormModel;

  protected readonly TCommonFieldSize = TCommonFieldSize;
  protected readonly TServicerType = TServicerType;
  protected readonly TRepairCalculationType = TRepairCalculationType;

  setRepairType(repairType: IRepairType): void {
    this.repairAction.repairTypeName = repairType?.name;
    this.repairAction.pricedByUnit = repairType.pricedByUnit;
    this.repairAction.reimbursementAmount = repairType.reimbursementAmount;
    this.repairAction.crmCalculationType = repairType.crmCalculationType;
    this.repairAction.repairTypeDescription = repairType.description;
    if (!repairType.pricedByUnit) {
      this.repairAction.calcReimbursementAmount = repairType.reimbursementAmount;
    }
  }

  setRepairTypeCategory(repairTypeCategory: IIdentity<string>): void {
    this.repairAction.repairTypeCategoryName = repairTypeCategory.name;
  }

  calcReimbursementAmount(): void {
    this.repairAction.calcReimbursementAmount = this.repairAction.quantity * this.repairAction.reimbursementAmount;
  }
}
