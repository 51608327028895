import { Type } from '@angular/core';

import { IWizardNavigation } from '@core/interfaces/wizard-navigation.interface';


export namespace StoreState {
  export interface IStoreState<TNavigationStep, TNavigationChildrenStep = unknown> {
    navigationList: IWizardNavigation<TNavigationStep, TNavigationChildrenStep>[];
    activeStep: TNavigationStep | TNavigationChildrenStep;
    activeComponent: Type<any>;
  }

  export const initialState: IStoreState<unknown> = {
    activeStep: '',
    activeComponent: null,
    navigationList: [],
  };
}
