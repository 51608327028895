import { Injectable } from '@angular/core';

import { THubMethods } from '@core/models/hub-event.model';
import { BaseHub } from '@core/services/hubs/base.hub';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class MjcMainHub {
  constructor(
    public readonly baseHub: BaseHub,
    public readonly modalDialogService: ModalDialogService,
  ) {
    baseHub.setConfig('claims', [
      THubMethods.JewelryClaimCreated,
      THubMethods.JewelryPlanCreated,
      THubMethods.DocsUploaded,
      THubMethods.ClaimStatusChange,
      THubMethods.ProtectionPlanDocsUploaded,
    ]);
    this.subscribeToUpdates();
  }

  startSignalR(): void {
    this.baseHub.startSignalR();
  }

  subscribeToUpdates(): void {
    // this.baseHub.eventBus$.subscribe(event => {});
  }
}
