export namespace StoreState {
  export interface IStoreState {
    submitInProgress: boolean;
    isLoadingReceiptFiles: boolean;
    planSubmitted: boolean;
    closeDialog: boolean;
    planSubmitResults: {
      [key: number]: {
        loading: boolean;
        planNumber: string;
        errorMessage: string;
      };
    };
    minPurchaseDate: Date;
    maxPurchaseDate: Date;
    purchaseDateAlertMessage: string;
    showAlertMessage: boolean;
  }

  export const initialState: IStoreState = {
    submitInProgress: false,
    isLoadingReceiptFiles: false,
    planSubmitted: false,
    planSubmitResults: null,
    closeDialog: false,
    minPurchaseDate: null,
    maxPurchaseDate: null,
    purchaseDateAlertMessage: null,
    showAlertMessage: false,
  };
}
