<app-side-dialog-animation *rxLet="vm$; let vm">
  <div class="plan-wizard__header-wrapper">
    <h2 class="plan-wizard__header">Register Protection Plan</h2>
    <div class="plan-wizard__header-actions">
      <app-alert-message
        *ngIf="vm.showAlertMessage"
        [title]="'NOTE:'"
        [message]="vm.purchaseDateAlertMessage"
        [type]="'warning'"></app-alert-message>
      <button
        mat-icon-button
        [disabled]="vm.submitInProgress"
        class="plan-wizard__close"
        (click)="close()">
        <mat-icon class="plan-wizard__header-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="plan-wizard">
    <app-wizard-nav
      [navigationList]="vm.navigationList"
      [activeStep]="vm.activeStep"></app-wizard-nav>
    <div class="plan-wizard__content">
      <ng-container *ngComponentOutlet="vm.activeComponent"></ng-container>
    </div>
  </div>
</app-side-dialog-animation>
