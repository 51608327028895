<app-side-dialog-animation
  *rxLet="vm$; let vm"
  class="_common-side-dialog_clear-view">
  <div class="create-claim__header-wrapper">
    <h2 class="create-claim__header">Claim for plan {{vm.planName}}</h2>
    <button
      mat-icon-button
      class="create-claim__close"
      (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="vm.isLoading; else wizardContent">
    <app-loader></app-loader>
  </ng-container>
  <ng-template #wizardContent>
    <div class="create-claim">
      <div class="create-claim__nav">
        <ng-container *ngFor="let nav of vm.navigationList">
          <div
            class="create-claim__nav-item"
            [class.create-claim__nav-item--active]="nav.id === (activeStep$ | push)">
            <div class="create-claim__nav-content">
              <p class="create-claim__nav-title">{{ nav.title }}</p>
              <div
                *rxIf="nav.valid$"
                class="create-claim__icon">
                <mat-icon class="_common-icon--white">done</mat-icon>
              </div>
            </div>
          </div>
          <div
            class="create-claim__nav-children"
            *ngIf="nav.children && nav.children.length > 0">
            <div
              class="create-claim__nav-item"
              *ngFor="let childNav of nav.children">
              <div class="create-claim__nav-content">
                <p class="create-claim__nav-title">{{ childNav.title }}</p>
                <div
                  *rxIf="childNav.valid$"
                  class="create-claim__icon">
                  <mat-icon class="_common-icon--white">done</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="create-claim__content">
        <ng-container *ngComponentOutlet="vm.activeComponent"></ng-container>
      </div>
    </div>
  </ng-template>
</app-side-dialog-animation>
