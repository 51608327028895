import { Injectable } from '@angular/core';
import {
  ClaimsAdvancedFilterComponent,
} from '@th-common-retailer/shared/pages/claims/containers/claims-advanced-filter/claims-advanced-filter.component';
import {
  PlansAdvancedFilterComponent,
} from '@th-common-retailer/shared/pages/plans/containers/plans-advanced-filter/plans-advanced-filter.component';

import { RouterConstants } from '@core/constants/router.constants';
import { Feature } from '@core/enums/feature.enum';
import { TPage } from '@core/enums/page.enum';
import { NavigationService } from '@core/services/navigation.service';
import { NavigationInterface } from '@shared/modules/navigation/components/navigation/navigation.component';

@Injectable()
export class MJCNavigationService extends NavigationService {
  getNavList(): NavigationInterface[] {
    return [
      {
        route: `/${TPage.App}/${TPage.Dashboard}`,
        name: 'Dashboard',
        // features: [Feature.ViewDashboard],
        features: [],
        child: [],
        icon: 'dashboard',
      },
      {
        route: `/${TPage.App}/${TPage.Plans}`,
        name: 'Plans',
        features: [],
        child: [],
        icon: 'plans',
        class: 'plans',
        filterComponent: PlansAdvancedFilterComponent,
      },
      {
        route: `/${TPage.App}/${TPage.Claims}`,
        name: 'Claims',
        features: [],
        child: [],
        icon: 'claim-2',
        class: 'claim',
        filterComponent: ClaimsAdvancedFilterComponent,
      },
      // {
      //   route: `/${TPage.App}/${TPage.PoPList}`,
      //   name: 'POP',
      //   features: [Feature.ViewDashboard],
      //   child: [
      //     {
      //       route: `/${TPage.App}/${TPage.PoPList}`,
      //       name: 'POP materials',
      //       features: [Feature.ViewDashboard],
      //       child: [],
      //       icon: '',
      //     },
      //   ],
      //   icon: 'pdf',
      // },
      {
        route: `/${TPage.App}/${TPage.Reports}`,
        name: 'REPORTS',
        features: [Feature.HasReports],
        child: [],
        icon: 'reports',
      },
      {
        route: '/app/admin',
        name: 'Admin',
        features: RouterConstants.features.admin,
        featureOptions: {
          some: true,
        },
        icon: 'admin',
        child: [
          {
            route: '/app/admin/users',
            name: 'Users',
            features: [Feature.ViewUsers],
          },
        ],
      },
      {
        route: '/app/contact-info',
        name: 'Contact Info',
        isAllow: true,
        child: [],
        icon: 'contact',
      },
      {
        route: '/app/help',
        name: 'Help',
        isAllow: true,
        icon: 'help',
        child: [],
      },
    ];
  }
}
