import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ControlContainer, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';

import { FormDateComponent } from '@shared/components/form-date/form-date.component';
import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { FormTextareaComponent } from '@shared/components/form-textarea/form-textarea.component';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';

import { ServicerTypeSelectComponent } from '../../../../shared/servicing-type-select/servicing-type-select.component';

@Component({
  selector: 'app-claim-replacement-template-form',
  templateUrl: './mjc-replacement-service-action-form.component.html',
  styleUrls: ['./mjc-replacement-service-action-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormDateComponent,
    FormTextareaComponent,
    FormSelectComponent,
    FormInputComponent,
    ReactiveFormsModule,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
    ServicerTypeSelectComponent,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class MjcReplacementServiceActionFormComponent {
  replacementRequestMinDate: Date;
  today = new Date();

  protected readonly TCommonFieldSize = TCommonFieldSize;
}
