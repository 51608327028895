<div>
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Medium">
      <app-form-input
        formControlName="replacementPrice"
        [dataType]="'price'"
        label="Replacement Price"></app-form-input>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block>
    <th-form-field [size]="TCommonFieldSize.Large">
      <app-form-textarea
        formControlName="replacementReason"
        [minRows]="1"
        label="Replacement reason"></app-form-textarea>
    </th-form-field>
  </th-form-field-block>
</div>
