import { inject, Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClaimApiService } from '@core/api/claim-api.service';
import { ServiceActionApi } from '@core/api/service-action-api';
import { TTileId } from '@core/enums/claim/tile-types.enum';
import { TConsumerPlanType } from '@core/enums/consumer-plan-type.enum';
import { DashboardTile, DashboardTileBase } from '@core/interfaces/dashboard-tile.interface';

@Injectable({
  providedIn: 'root',
})
export class DashboardTileService {
  claimApiService = inject(ClaimApiService);
  serviceActionApi = inject(ServiceActionApi);

  getTilesData(tiles: DashboardTileBase[], consumerPlanType: TConsumerPlanType): Observable<DashboardTile[]> {
    return zip(
      ...tiles.map(tile => {
        if (tile.tileDataType === 'claims') {
          return this.claimApiService.all(
            {
              ...tile.searchRequest,
              consumerPlanType,
              noRows: true,
            },
            {
              tileId: TTileId[tile.id],
            },
          );
        } else {
          const { serviceActionFilters, ...params } = tile.searchRequest;
          return zip(...serviceActionFilters.map(filter => this.serviceActionApi.all(
            {
              ...params,
              ...filter,
              noRows: true,
            },
            {
              tileId: TTileId[tile.id],
            },
          ))).pipe(
            map(serviceActions => ({
              totalCount: serviceActions.reduce((acc, curr) => acc + curr.totalCount, 0),
            })),
          );
        }
      }),
    ).pipe(
      map(tilesCount => tiles.map((tile, index) => ({
        ...tile,
        count: tilesCount[index].totalCount,
        loading: false,
      }))),
    );
  }
}
