import { QuickSearchConsumer } from '../../interfaces/quick-search/search-results.interface';

export namespace StoreState {
  export interface IStoreState {
    searchQuery: string;
    searchResults: QuickSearchConsumer[];
    noResultsFound: boolean;
    loading: boolean;
  }

  export const initialState: IStoreState = {
    searchQuery: null,
    searchResults: [],
    noResultsFound: false,
    loading: false,
  };
}
