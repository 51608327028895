import { Directive, Input, Output } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appMaxValue][ngModel]',
  standalone: true,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxValueDirective,
      multi: true,
    },
  ],
})
export class MaxValueDirective implements Validator {
  @Input() set appMaxValue(appMaxValue: number) {
    this.validate = Validators.max(appMaxValue);
    this.validationUpdated.next();
  }

  @Output() validationUpdated = new Subject<void>();

  validate = Validators.max(undefined);

}
