import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, distinctUntilChanged, finalize, map, startWith } from 'rxjs/operators';

import { IMjcPlanRegisterResponse, RetailerPlanApiService } from '@core/api/retailer-plan-api.service';
import { AppConstants } from '@core/constants/app.constants';
import { TDocumentType } from '@core/enums/document-type';
import { Files } from '@core/interfaces/claims/files.interface';
import { IWizardNavigation } from '@core/interfaces/wizard-navigation.interface';
import { ClaimFormService } from '@core/services/claim-form.service';
import { BaseStore } from '@core/store/_base/base.store';
import { WizardNavigationStore } from '@core/store/wizard/wizard-navigation.store';
import { DateValidation } from '@core/utils/form.util';

import { PlanInfoWizardStepComponent } from '../containers/plan-info-wizard-step/plan-info-wizard-step.component';
import { IRegisterPlanForm } from './plan-wizard.interface';
import { StoreState } from './plan-wizard.state';
import IStoreState = StoreState.IStoreState;
import initialState = StoreState.initialState;
import moment, { parseZone } from 'moment';
import { Observable, of, switchMap, tap, zip } from 'rxjs';

import { UserStore } from '@core/store/user/user.store';

export type TPlanWizardSteps = 'consumer' | 'products' | 'summary';
export type TPlanWizardChildSteps = `product${number}`;

function validatePlanMaxFilesSize(maxFilesSize: number = AppConstants.maxFilesSize) {
  return (formArray: FormArray<FormControl<Files>>) => {
    const receiptFiles = formArray.value.filter(file => file.docType === TDocumentType.Receipt);
    const productFiles = formArray.value.filter(file => file.docType === TDocumentType.CustPhoto);
    const totalFilesSize = [...receiptFiles, ...productFiles].reduce((filesSize, file) => {
      filesSize += file.size;
      return filesSize;
    }, 0);
    if (totalFilesSize > maxFilesSize) {
      return {
        sizeError: {
          maxFilesSize,
          totalFilesSize,
        },
      };
    } else {
      return null;
    }
  };
}

@Injectable()
export class PlanWizardStore extends BaseStore<IStoreState> {
  form = this.fb.group({
    consumer: this.fb.group({
      info: this.fb.group({
        id: [null],
        crmRefId: [null],
        contactId: [null],
        firstName: ['', [Validators.required, Validators.maxLength(50), ClaimFormService.ValidateFirstLastName()]],
        lastName: ['', [Validators.required, Validators.maxLength(50), ClaimFormService.ValidateFirstLastName(2)]],
        emailAddress: ['', [Validators.pattern(AppConstants.emailRegEx), Validators.maxLength(100)]],
        mobilePhone: ['', [Validators.maxLength(50), ClaimFormService.phoneNumber]],
        homePhone: ['', [Validators.maxLength(50), ClaimFormService.phoneNumber]],
        workPhone: ['', [Validators.maxLength(50), ClaimFormService.phoneNumber]],
      }, {
        validators: [ClaimFormService.ValidateConsumerPhones],
      }),
      consumerAddress: this.fb.group({
        streetAddress1: ['', [Validators.required, Validators.maxLength(250)]],
        streetAddress2:  ['', [Validators.maxLength(250)]],
        city: ['', [Validators.required, Validators.maxLength(80)]],
        stateProvince: ['', [Validators.required, Validators.maxLength(50)]],
        postalCode: ['', [Validators.required, Validators.maxLength(20)]],
        isValidated: [false],
        addressValidationAttempted: [false],
      }),
    }),
    plans: this.fb.array([this._returnPlanControl()]),
  });

  constructor(
    public readonly ws: WizardNavigationStore<TPlanWizardSteps, TPlanWizardChildSteps>,
    private readonly fb: FormBuilder,
    private readonly retailerPlanApiService: RetailerPlanApiService,
    private readonly userStore: UserStore,
  ) {
    super(initialState);
    const maxSalesRegistrationDays = this.userStore.get('session', 'maxSalesRegistrationDays');
    const maxPurchaseDate = moment().utc().startOf('day').set('hours', 12);
    const minPurchaseDate = parseZone(maxPurchaseDate).subtract(maxSalesRegistrationDays - 1, 'days');
    this.updateState({
      minPurchaseDate: minPurchaseDate.toDate(),
      maxPurchaseDate: maxPurchaseDate.toDate(),
      // eslint-disable-next-line max-len
      purchaseDateAlertMessage: `Plan sales are permitted to be registered within the past <b>${maxSalesRegistrationDays}</b> days. As of today, the oldest allowable purchase date is <b>${minPurchaseDate.format(
        'MM/DD/YYYY',
      )}</b>.`,
    });
  }

  get currentProductIndex(): number | null {
    const activeStep = this.ws.getActiveStep();
    return activeStep.includes('product') ? Number(activeStep.replace('product', '')) : null;
  }

  goBack(): void {
    const currentProductIndex = this.currentProductIndex;
    if (currentProductIndex === null) {
      this.ws.stepBack();
    } else if (currentProductIndex > 0) {
      this.ws.setStep(`product${currentProductIndex - 1}`);
    } else {
      this.ws.setStep('consumer');
    }
  }

  goNext(): void {
    const plans = this.form.value.plans;
    const currentProductIndex = this.currentProductIndex;
    if (currentProductIndex === null) {
      this.ws.nextStep();
    } else {
      if (currentProductIndex < plans.length - 1) {
        this.ws.setStep(`product${currentProductIndex + 1}`);
      } else {
        this.ws.setStep('summary');
      }
    }
  }

  showAlertMessage(): void {
    this.updateState({
      showAlertMessage: true,
    });
  }

  hideAlertMessage(): void {
    this.updateState({
      showAlertMessage: false,
    });
  }

  getForm(key: string = ''): FormGroup {
    if (key) {
      return this.form.get(key) as FormGroup;
    } else {
      return this.form;
    }
  }

  setNavigationList(param: IWizardNavigation<TPlanWizardSteps, TPlanWizardChildSteps>[]): void {
    this.ws.setNavigationList(param);
  }

  addPlan(insertIndex = null): void {
    const navList = this.ws.getNavigationList();
    const planChildren = navList.find(item => item.id === 'products').children;
    const lastPlanIndex = planChildren.length - 1;
    const lastPlanNameIndex = Number(planChildren[planChildren.length - 1].title.replace('Product ', ''));
    const productNavItem: IWizardNavigation<TPlanWizardChildSteps> = {
      id: `product${lastPlanIndex + 1}`,
      title: `Product ${lastPlanNameIndex + 1}`,
      valid$: null,
      component: PlanInfoWizardStepComponent,
    };

    const productControl = this._returnPlanControl();
    if (insertIndex === null) {
      (this.form.get('plans') as FormArray).push(productControl);
      planChildren.push(productNavItem);
    } else {
      (this.form.get('plans') as FormArray).insert(insertIndex, productControl);
      planChildren.splice(insertIndex, 0, productNavItem);
    }
    this.ws.setNavigationList([
      ...navList.map(item => {
        if (item.id !== 'products') {
          return item;
        }

        return {
          ...item,
          children: [
            ...planChildren.map((childItem, childItemIndex) => ({
              ...childItem,
              id: `product${childItemIndex}`,
              valid$: (this.form.get('plans') as FormArray).at(childItemIndex).statusChanges.pipe(
                startWith((this.form.get('plans') as FormArray).at(childItemIndex).status),
                distinctUntilChanged(),
                map(status => status === 'VALID'),
              ),
            } as IWizardNavigation<TPlanWizardChildSteps>)),
          ],
        };
      }),
    ]);
  }

  removePlan(): void {
    const activePlanIndex = this.currentProductIndex;
    const plans = (this.form.get('plans') as FormArray);
    plans.removeAt(activePlanIndex);
    const navigationList = this.ws.getNavigationList();
    this.ws.setNavigationList([
      ...navigationList.map(item => {
        if (item.id !== 'products') {
          return item;
        }
        return {
          ...item,
          children: [
            ...item.children.filter((child, childIndex) => childIndex !== activePlanIndex).map((child, childIndex) => ({
              ...child,
              id: `product${childIndex}`,
            } as IWizardNavigation<TPlanWizardChildSteps>)),
          ],
        };
      }),
    ]);

    if (plans.value.length === 0) {
      this.goBack();
    } else {
      this.ws.setStep(`product${activePlanIndex === plans.value.length ? activePlanIndex - 1 : activePlanIndex}`);
    }
  }

  clearConsumer(): void {
    this.form.reset();
    this.form.enable();
  }

  submit(): void {
    this.updateState({
      submitInProgress: true,
    });
    const formValue = this.form.getRawValue() as IRegisterPlanForm;
    const planSubmitResults = formValue.plans.reduce((resultsObject, plan, index) => {
      resultsObject[index] = {
        loading: true,
        planNumber: null,
        errorMessage: null,
      };
      return resultsObject;
    }, {});
    let planRequestStream$: Observable<IMjcPlanRegisterResponse[]>;
    if (!formValue.consumer.info.id) {
      const [firstPlan, ...restPlans] = formValue.plans;
      planRequestStream$ = this._returnPlanRequest(formValue, 0).pipe(
        switchMap((firstPlanResponse) => {
          if (restPlans.length === 0) {
            return of([]);
          } else if (!firstPlanResponse) {
            restPlans.forEach((plan, index) => {
              this._setSubmitResultError(index + 1);
            });
            return of([]);
          } else {
            const planRequests = restPlans.map((plan, index) => this._returnPlanRequest({
              ...formValue,
              consumer: {
                ...formValue.consumer,
                info: {
                  ...formValue.consumer.info,
                  crmRefId: firstPlanResponse.crmContactAccountId,
                },
              },
            }, index + 1));
            return zip(...planRequests);
          }
        }),
      );
    } else {
      planRequestStream$ = zip(...formValue.plans.map((plan, index) => this._returnPlanRequest(formValue, index)));
    }

    this.updateState({
      planSubmitResults,
    });

    planRequestStream$.pipe(
      finalize(() => {
        this.updateState({
          submitInProgress: false,
        });
      }),
    ).subscribe(() => {
      this.form.markAsPristine();
      this.updateState({
        planSubmitted: true,
      });
    });
  }

  closeDialog(): void {
    this.updateState({
      closeDialog: true,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private _returnPlanControl() {
    return this.fb.group({
      info: this.fb.group({
        retailerId: [null, Validators.required],
        retailerName: [{
          value: null,
          disabled: true,
        }],
        planId: [null, Validators.required],
        planName: [{
          value: null,
          disabled: true,
        }],
        planPrice: [null, [Validators.required, Validators.maxLength(100)]],
        receiptNumber: ['', [Validators.required, Validators.maxLength(100)]],
        purchaseDate: ['', [DateValidation, Validators.required]],
      }),
      coveredProduct: this.fb.group({
        type: [null, Validators.required],
        crmManufacturerId: [null, [Validators.maxLength(100)]],
        manufacturerName: [{
          value: null,
          disabled: true,
        }],
        productName: ['', [Validators.required, Validators.maxLength(100)]],
        sku: ['', [Validators.required, Validators.maxLength(100)]],
        unitPrice: [null, [Validators.required, Validators.maxLength(100)]],
        productDescription: ['', [Validators.required, Validators.maxLength(2000)]],
      }),
      files: this.fb.array<Files>([], [validatePlanMaxFilesSize()]),
    });
  }

  private _returnPlanRequest(planForm: IRegisterPlanForm, index: number): Observable<IMjcPlanRegisterResponse> {
    const plan = planForm.plans[index];
    return this.retailerPlanApiService.registerMjcPlan({
      consumer: planForm.consumer.info,
      consumerPlan: {
        retailerId: plan.info.retailerId,
        crmParentPlanId: plan.info.planId,
        planPrice: plan.info.planPrice,
        receiptNumber: plan.info.receiptNumber,
        purchaseDate: plan.info.purchaseDate,
      },
      coveredProduct: {
        productType: plan.coveredProduct.type,
        crmManufacturerId: plan.coveredProduct.crmManufacturerId,
        productName: plan.coveredProduct.productName,
        sku: plan.coveredProduct.sku,
        unitPrice: plan.coveredProduct.unitPrice,
        productDescription: plan.coveredProduct.productDescription,
      },
      consumerAddress: {
        ...planForm.consumer.consumerAddress,
      },
    }, plan.files).pipe(
      tap(registeredPlanResponse => {
        this.updateState((oldState) => ({
          ...oldState,
          planSubmitResults: {
            ...oldState.planSubmitResults,
            [index]: {
              loading: false,
              planNumber: registeredPlanResponse.planNumber,
              errorMessage: null,
            },
          },
        }));
      }),
      catchError(() => {
        this._setSubmitResultError(index);
        return of(null);
      }),
    );
  }

  private _setSubmitResultError(index: number) {
    this.updateState((oldState) => ({
      ...oldState,
      planSubmitResults: {
        ...oldState.planSubmitResults,
        [index]: {
          loading: false,
          planNumber: null,
          errorMessage:
            'An error occurred while registering the plan. Please contact Montage via the Contact Info section of the portal.',
        },
      },
    }));
  }
}
