import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TCommonFieldSize } from '@shared/components/form-field/common-field-size.enum';
import { FormInputComponent } from '@shared/components/form-input/form-input.component';
import { ReadonlyControlDirective } from '@shared/directives/readonly-control.directive';
import { ThFormFieldComponent } from '@shared/form/form-field/form-field.component';
import { ThFormFieldBlockComponent } from '@shared/form/th-form-field-block/th-form-field-block.component';
import { SelectsModule } from '@shared/modules/selects/selects.module';
import { LabelValueItemComponent } from '@shared/standalone/label-value-item/label-value-item.component';

@Component({
  selector: 'app-address-template-form',
  templateUrl: './address-template-form.component.html',
  styleUrls: ['./address-template-form.component.scss'],
  standalone: true,
  imports: [
    SelectsModule,
    FormInputComponent,
    ReactiveFormsModule,
    ThFormFieldBlockComponent,
    ThFormFieldComponent,
    ReadonlyControlDirective,
    LabelValueItemComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressTemplateFormComponent {
  @Input() formGroup: FormGroup;
  @Input() blocked: boolean;
  protected readonly TCommonFieldSize = TCommonFieldSize;
}
