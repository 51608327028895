import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';

import { QuickSearchStore } from '@core/store/quick-search/quick-search.store';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';

interface IConsumerSearchControlState {
  noResultsFound: boolean;
  searchLoading: boolean;
}

@Component({
  selector: 'app-consumer-search-control',
  standalone: true,
  imports: [SearchInputComponent, LetDirective, NgIf],
  templateUrl: './consumer-search-control.component.html',
  styleUrls: ['./consumer-search-control.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumerSearchControlComponent {
  vm$ = this.state.select();

  constructor(
    private readonly state: RxState<IConsumerSearchControlState>,
    private readonly quickSearchStore: QuickSearchStore,
  ) {
    this.state.connect('searchLoading', this.quickSearchStore.select$('loading'));
    this.state.connect('noResultsFound', this.quickSearchStore.select$('noResultsFound'));
  }

  quickSearch(searchString: string): void {
    this.quickSearchStore.search(searchString);
  }

  clearSearch(): void {
    this.quickSearchStore.clearSearch();
  }
}
