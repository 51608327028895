import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, TemplateRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { map, startWith } from 'rxjs/operators';

import { LoadingButtonDirective } from '@shared/directives/loading-button.directive';
import { AltTableDirective } from '@shared/directives/table/alt-table.directive';
import { IAppTable } from '@shared/modules/table/interfaces/table.interface';
import { IAppTableRow } from '@shared/modules/table/interfaces/table-row.interface';
import { ThTableComponent } from '@shared/modules/table/table.component';
import { ThTableService } from '@shared/modules/table/table.service';
import { LabelValueItemComponent } from '@shared/standalone/label-value-item/label-value-item.component';
import { WizardFormSectionComponent } from '@shared/wizard/wizard-form-section/wizard-form-section.component';

import {
  IRepairAction,
} from '../../../../core/interfaces/repair-service-action.interface';
import {
  MjcRepairActionFormActionsCellComponent,
} from '../../components/mjc-repair-action-form-actions-cell/mjc-repair-action-form-actions-cell.component';
import {
  MjcReplacementServiceActionFormComponent,
} from '../../components/mjc-replacement-service-action-form/mjc-replacement-service-action-form.component';
import {
  RepairServiceActionDialogComponent,
} from '../../dialogs/repair-service-action-dialog/repair-service-action-dialog.component';
import { IClaimFormModel } from '../../interfaces/claim-form.model';
import { MjcCreateClaimStore } from '../../store/mjc-create-claim.store';

interface IMjcClaimServiceActionsWizardState {
  repairActionsTable: IAppTable;
  repairActions: IRepairAction[];
  submitInProgress: boolean;
  totalEstimatedReimbursementAmount: number;
}

@Component({
  selector: 'app-mjc-claim-service-actions-wizard',
  standalone: true,
  imports: [
    CommonModule,
    MjcReplacementServiceActionFormComponent,
    ReactiveFormsModule,
    MatButtonModule,
    RepairServiceActionDialogComponent,
    ThTableComponent,
    LetDirective,
    MjcRepairActionFormActionsCellComponent,
    AltTableDirective,
    WizardFormSectionComponent,
    LoadingButtonDirective,
    LabelValueItemComponent,
  ],
  templateUrl: './mjc-claim-service-actions-wizard.component.html',
  styleUrls: ['./mjc-claim-service-actions-wizard.component.scss'],
  providers: [
    RxState,
    CurrencyPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MjcClaimServiceActionsWizardComponent {
  store = inject(MjcCreateClaimStore);
  vm$ = this.state.select();
  claimType: 'Repair' | 'Replacement' = this.store.form.get('product.incident.claimType').value;
  replacementActionForm = this.store.replacementActionForm;
  repairActionsForm = this.store.repairActionsForm;
  form = this.store.form;

  constructor(
    private readonly state: RxState<IMjcClaimServiceActionsWizardState>,
    private readonly currencyPipe: CurrencyPipe,
  ) {
    this.state.set({
      totalEstimatedReimbursementAmount: null,
    });
    this.state.connect(this.repairActionsForm.valueChanges.pipe(
      startWith(this.repairActionsForm.getRawValue()),
      map(repairActions => ({
        repairActionsTable: this._buildRepairActionsTable(this.repairActionsForm.getRawValue()),
        repairActions,
        totalEstimatedReimbursementAmount: repairActions.length === 0 ? null : repairActions.reduce(
          (acc, item) => acc + (item.estimatedAmount || item.calcReimbursementAmount), 0,
        ),
      })),
    ));
    this.state.connect('submitInProgress', this.store.select$('submitInProgress'));
  }

  addNewRepairAction(repairActionDialogTemplate: TemplateRef<RepairServiceActionDialogComponent>): void {
    this.store.openRepairActionDialog({
      product: this.store.form.value.product,
    }, repairActionDialogTemplate, {
      title: 'Add Repair Action',
      actionBtnLabel: 'Add',
    }).afterClosed().subscribe(result => {
      if (result?.repairAction) {
        this.store.addNewRepairAction(result.repairAction);
      }
    });
  }

  goBack(): void {
    this.store.goBack();
  }

  submit(): void {
    this.store.submit();
  }

  private _buildRepairActionsTable(repairActions: IRepairAction[]): IAppTable {
    const rows = repairActions.map((item, index) => this._buildRepairActionRow(item, index));
    return {
      rows,
      columns: [
        ThTableService.buildCol('repairTypeCategory', 'Repair Type Category'),
        ThTableService.buildCol('crmRepairTypeId', 'Repair Type'),
        ThTableService.buildCol('servicerType', 'Servicer Type'),
        ThTableService.buildCol('amount', 'Amount'),
        ThTableService.buildCol('quantity', 'Quantity'),
        ThTableService.buildCol('actions', ''),
      ],
    };
  }

  private _buildRepairActionRow(item: IRepairAction, index: number): IAppTableRow {
    const servicerTypeName = (this.store.form.getRawValue() as IClaimFormModel).product.incident.servicerTypeName;
    const amount = item.estimatedAmount
      ? this.currencyPipe.transform(item.estimatedAmount) : this.currencyPipe.transform(item.calcReimbursementAmount);

    return {
      repairTypeCategory: ThTableService.buildCell(item.repairTypeCategoryName),
      crmRepairTypeId: ThTableService.buildCell(item.repairTypeName),
      servicerType: ThTableService.buildCell(servicerTypeName),
      amount: amount
        ? ThTableService.buildCell((item.estimatedAmount ? `${amount} (est.)` : amount))
        : ThTableService.buildCell('-'),
      quantity: item.quantity
        ? ThTableService.buildCellNumber(item.quantity, '1.0-0') : ThTableService.buildCell('-'),
      actions: ThTableService.buildCellCustom({
        type: 'actions',
        repairAction: item,
        repairActionIndex: index,
      }),
    };
  }
}
