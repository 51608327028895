import { Injectable } from '@angular/core';
import { IProcessStage } from '@th-common-retailer/core/interfaces/process-stage.interface';
import {
  ClaimDetailsComponent,
} from '@th-common-retailer/dialogs/claim-details/claim-details.component';
import {
  JewelryClaimCompleteDialogComponent,
} from '@th-common-retailer/dialogs/claim-details/dialogs/jewelry-claim-complete-dialog/jewelry-claim-complete-dialog.component';
import {
  ClaimsPageService,
  IServiceActionFilterCheckbox,
} from '@th-common-retailer/shared/pages/claims/claims-page.service';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { SERVICER_TYPE_TO_TITLE, TServicerType } from 'th-mjc/app/core/enums/servicing-type.enum';

import { ClaimApiService } from '@core/api/claim-api.service';
import { AppConstants } from '@core/constants/app.constants';
import { DIALOG_CONFIG_CUSTOM_ANIMATION } from '@core/constants/dialog.constants';
import { TServiceActionTileStage } from '@core/enums/service-action/service-action-tile-stage.enum';
import { TServiceActionType } from '@core/enums/service-action/service-action-type.enum';
import { ClaimDetails, IClaimDetailsModel } from '@core/interfaces/claims/claimDetails.interface';
import { AdvancedSearchService } from '@core/services/advanced-search.service';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { BaseClaimDetailsTablesConfig } from '@shared/modules/claim-details/classes/base-claim-details-tables-config';
import { ClaimFlaggedCellComponent } from '@shared/modules/claim-grid/components/claim-flagged-cell/claim-flagged-cell.component';
import { ClaimStagesCellComponent } from '@shared/modules/claim-grid/components/claim-stages-cell/claim-stages-cell.component';
import { ClaimStatusCellComponent } from '@shared/modules/claim-grid/components/claim-status-cell/claim-status-cell.component';
import { GridDateCellComponent } from '@shared/modules/grid/components/grid-date-cell/grid-date-cell.component';
import { GridColumnModel } from '@shared/modules/grid/models/grid/grid-column.model';

import { RetailerTileFilter } from '../../../../th-retailer/app/core/constants/tile-stage-filter.constant';
import { getTileSearchRequest, MjcTileFilter } from '../../core/constants/mjc-tile-stage-filter.constant';
import { MjcDashboardStore } from '../dashboard/store/mjc-dashboard.store';
import { MjcClaimDetailsCellComponent } from './components/mjc-claim-details-cell/mjc-claim-details-cell.component';

@Injectable({
  providedIn: 'root',
})
export class MjcClaimsPageService extends ClaimsPageService {
  showPortalTypeTabs = false;

  processStages: IProcessStage[] = [
    {
      id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Repairs),
      name: 'Repairs',
      features: [],
      children: [
        {
          id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Repairs, TServiceActionTileStage.RepairAuthorized),
          name: AppConstants.serviceActionTileStages[TServiceActionTileStage.RepairAuthorized],
        },
        {
          id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Repairs, TServiceActionTileStage.ReadyToShip),
          name: AppConstants.serviceActionTileStages[TServiceActionTileStage.ReadyToShip],
        },
      ],
    },
    {
      id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement),
      name: 'Replacement',
      features: [],
      children: [
        {
          id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement, TServiceActionTileStage.ReplacementAuthorized),
          name: AppConstants.serviceActionTileStages[TServiceActionTileStage.ReplacementAuthorized],
        },
        // {
        //   id: RetailerTileFilter.getActionTypeTileStageKey(TServiceActionType.Replacement, TServiceActionTileStage.EnterDelivery),
        //   name: AppConstants.serviceActionTileStages[TServiceActionTileStage.EnterDelivery],
        // },
      ],
    },
  ];

  tileFilter = MjcTileFilter;
  getTileSearchRequest = getTileSearchRequest;

  claimWarrantyColumns: GridColumnModel[] = [
    new GridColumnModel('receiptNumber', 'Receipt Number'),
    new GridColumnModel('name', 'Consumer Agreement Number'),
    new GridColumnModel('purchaseDate', 'Purchase', GridDateCellComponent),
  ];
  claimProductsColumns: GridColumnModel[] = BaseClaimDetailsTablesConfig.claimProductsColumns.filter(column => column.title !== 'Cause');

  constructor(
    private readonly modalDialogService: ModalDialogService,
    private readonly mjcDashboardStore: MjcDashboardStore,
    private readonly advancedSearchService: AdvancedSearchService,
    private readonly claimApiService: ClaimApiService,
  ) {
    super();
  }

  openClaimDetailsDialog(claimId: number, singleView = false): void {
    this.modalDialogService.open({
      title: 'Claim Details',
      data: {
        claimInfo: { id: claimId },
        singleView,
      },
    }, ClaimDetailsComponent, DIALOG_CONFIG_CUSTOM_ANIMATION);
  }

  getDefaultColumns(showFlag = true): GridColumnModel[] {
    return [
      new GridColumnModel('claimNumber', 'Claim #', MjcClaimDetailsCellComponent, false),
      new GridColumnModel('claimStatus', 'Status', ClaimStatusCellComponent),
      new GridColumnModel('claimStage', 'Stage', ClaimStagesCellComponent),
      new GridColumnModel('retailerName', 'Store', null),
      new GridColumnModel('dateOpen', 'Opened', GridDateCellComponent),
      new GridColumnModel('dateClosed', 'Closed', GridDateCellComponent),
      new GridColumnModel('receiptNumber', 'Receipt Number', null),
      new GridColumnModel('consumerName', 'Consumer', null),
      new GridColumnModel(
        'isFlaggedForRetailerReview',
        'Review',
        ClaimFlaggedCellComponent,
        true,
        true,
        showFlag,
      ),
    ];
  }

  getAdvancedSearchActionFilters(): Observable<IServiceActionFilterCheckbox[]> {
    return this.mjcDashboardStore.select$('actionTiles').pipe(
      withLatestFrom(this.mjcDashboardStore.select$('statTiles')),
      map(tiles => {
        const tileSearch = { ...this.advancedSearchService.tileSearch };
        return tiles
          .flat()
          .map(tile => ({
            ...tile,
            title: tile.checkboxTitle || tile.title,
            checked: !!tileSearch && tileSearch.tileId === tile.id,
          }));
      }),
    );
  }

  showCompleteRepairBtn(claim: ClaimDetails): boolean {
    return claim.serviceActions.some(
      serviceAction => MjcTileFilter.matchServiceActionWithSearchRequest(serviceAction, MjcTileFilter.getRepairsAuthorizedFilters())
        || MjcTileFilter.matchServiceActionWithSearchRequest(serviceAction, MjcTileFilter.getReplacementAuthorizedStageFilters()));
  }

  completeJewelryClaim(claim: IClaimDetailsModel): Observable<boolean> {
    const claimId = claim.claimInfo.id;
    if (claim.serviceActions.some(serviceAction => serviceAction.serviceActionType === TServiceActionType.Replacement)) {
      return this._openCompleteDialog(claimId, TServiceActionType.Replacement, claim.serviceActions[0].authorizedAmount);
    } else if (claim.serviceActions.some(serviceAction => serviceAction.serviceActionType === TServiceActionType.Repair)) {
      if (claim.serviceActions[0].repairServicingType === SERVICER_TYPE_TO_TITLE[TServicerType.ThirdPartyRepairDepot]) {
        return this.claimApiService.markCompleted(claimId).pipe(
          map(() => true),
        );
      } else {
        return this._openCompleteDialog(claimId, TServiceActionType.Repair, claim.serviceActions[0].authorizedAmount);
      }
    }
  }

  private _openCompleteDialog(
    claimId: number,
    serviceActionType: TServiceActionType,
    authorizedAmount: number,
  ): Observable<boolean> {
    return this.modalDialogService.open({
      title: 'Complete Repair',
      data: {
        claimId,
        serviceActionType,
        authorizedAmount,
      },
    }, JewelryClaimCompleteDialogComponent, {
      disableClose: true,
      maxWidth: '600px',
      width: '600px',
    }).afterClosed();
  }
}
