<div>
  <th-form-field-block>
    <app-form-input
      hidden
      [ngModel]="repairAction.repairTypeCategoryName"
      name="repairTypeCategoryName"></app-form-input>
    <th-form-field [size]="TCommonFieldSize.Half">
      <app-repair-type-category-select
        [(ngModel)]="repairAction.repairTypeCategory"
        [required]="true"
        name="repairTypeCategory"
        (repairTypeCategoryChanged)="setRepairTypeCategory($event)"></app-repair-type-category-select>
    </th-form-field>
    <app-form-input
      hidden
      [ngModel]="repairAction.repairTypeName"
      name="repairTypeName"></app-form-input>
    <app-form-input
      hidden
      [ngModel]="repairAction.pricedByUnit"
      name="pricedByUnit"></app-form-input>
    <app-form-input
      hidden
      [ngModel]="repairAction.reimbursementAmount"
      name="reimbursementAmount"></app-form-input>
    <app-form-input
      hidden
      [ngModel]="repairAction.calcReimbursementAmount"
      name="calcReimbursementAmount"></app-form-input>
    <app-form-input
      hidden
      [ngModel]="repairAction.crmCalculationType"
      name="crmCalculationType"></app-form-input>
    <app-form-input
      hidden
      [ngModel]="repairAction.repairTypeDescription"
      name="repairTypeDescription"></app-form-input>
    <th-form-field [size]="TCommonFieldSize.Half">
      <app-repair-type-select
        [(ngModel)]="repairAction.crmRepairTypeId"
        [required]="true"
        [disabled]="!repairAction.repairTypeCategory"
        name="crmRepairTypeId"
        [repairTypeCategoryId]="repairAction.repairTypeCategory"
        (repairTypeChanged)="setRepairType($event)"></app-repair-type-select>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block *ngIf="repairAction.pricedByUnit">
    <th-form-field [size]="TCommonFieldSize.Half">
      <app-form-input
        class="_common-input--relative-hint"
        label="Quantity"
        [required]="true"
        [hint]="repairAction.repairTypeDescription"
        [appMaxValue]="100"
        dataType="only-number"
        [(ngModel)]="repairAction.quantity"
        name="quantity"
        (ngModelChange)="calcReimbursementAmount()"></app-form-input>
    </th-form-field>
  </th-form-field-block>
  <th-form-field-block>
    <ng-container
      *ngIf="
        !!product.incident.servicerType &&
        product.incident.servicerType !== TServicerType.ThirdPartyRepairDepot
      ">
      <th-form-field
        *ngIf="
          repairAction.crmCalculationType === TRepairCalculationType.StandardReimbursementRate;
          else estimatedAmountTemplate
        "
        [size]="TCommonFieldSize.Half">
        <app-label-value-item
          label="Reimbursement Amount"
          [value]="repairAction.calcReimbursementAmount | currency"></app-label-value-item>
      </th-form-field>
    </ng-container>
  </th-form-field-block>
  <th-form-field-block>
    <th-form-field>
      <app-form-textarea
        [(ngModel)]="repairAction.reason"
        name="reason"
        label="Repair reason"
        [required]="true"
        [appMaxLength]="2000"
        [minRows]="1"></app-form-textarea>
    </th-form-field>
  </th-form-field-block>
</div>
<ng-template #estimatedAmountTemplate>
  <th-form-field [size]="TCommonFieldSize.Half">
    <app-form-input
      label="Estimate Amount"
      dataType="price"
      [appMaxValue]="9999999999.99"
      [required]="true"
      [(ngModel)]="repairAction.estimatedAmount"
      name="estimatedAmount"></app-form-input>
  </th-form-field>
</ng-template>
