import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CrudApiBaseService } from '@core/api/crud-api.base';
import { IIdentity } from '@core/interfaces/util/identity.interface';

import { IRepairType } from '../interfaces/repair-service-action.interface';


@Injectable({ providedIn: 'root' })
export class RepairTypeApi extends CrudApiBaseService<IIdentity<string>> {
  endpoint = 'repairtype';

  getRepairCategories(): Observable<IIdentity<string>[]> {
    return this.httpClient.get<IIdentity<string>[]>(this.getUrl('category/list'));
  }

  getRepairTypes(crmRepairTypeCategoryId: string): Observable<IRepairType[]> {
    return this.httpClient.get<IRepairType[]>(this.getUrl(`list/${crmRepairTypeCategoryId}`));
  }
}
