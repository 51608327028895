import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RxState } from '@rx-angular/state';
import { LetDirective } from '@rx-angular/template/let';
import { finalize, map } from 'rxjs/operators';

import { LookupApiService } from '@core/api/lookup-api.service';
import { IIdentity } from '@core/interfaces/util/identity.interface';
import { FormSelectComponent } from '@shared/components/form-select/form-select.component';
import { ThBaseFormControlElement } from '@shared/form/base-form-control-element.directive';

import { TServicerType } from '../../core/enums/servicing-type.enum';


interface IServicerTypeSelectState {
  servicerTypes: IIdentity[];
  loading: boolean;
}

@Component({
  selector: 'app-servicer-type-select',
  standalone: true,
  imports: [
    FormSelectComponent,
    LetDirective,
    ReactiveFormsModule,
  ],
  templateUrl: './servicing-type-select.component.html',
  styleUrls: ['./servicing-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicerTypeSelectComponent extends ThBaseFormControlElement<number> {
  @Input() hasDefaultServicingRetailer = false;
  @Output() servicerTypeChanged = new EventEmitter<IIdentity>();

  vm$ = this.state.select();

  constructor(
    private readonly lookupApiService: LookupApiService,
    private readonly state: RxState<IServicerTypeSelectState>,
  ) {
    super();
    this.state.set({
      loading: true,
    });
    this.state.connect('servicerTypes', this.lookupApiService.getRepairServicerType().pipe(
      map((servicerTypes) => {
        if (!this.hasDefaultServicingRetailer) {
          return servicerTypes.filter((servicerType) => servicerType.id !== TServicerType.AssignedServicingRetailer);
        }
        return servicerTypes;
      }),
      finalize(() => this.state.set({ loading: false })),
    ));
  }
}
